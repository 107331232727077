import { useEffect, useState } from 'react';
import { SortableLotInfoBlock, sortableLotInfoBlocks } from '@/components/CallFeedTab/constants';
import useLocalStorage from '@/components/hooks/useLocalStorage';
import { SelectPicker } from '@/components/SelectPicker/SelectPicker';
import Box from '@mui/material/Box';
import { Subset } from '@/common/types/subset';
import { PenLot } from '@/web-types';
import { emDash } from '@/components/Typography/emDash';
import IconButton from '@mui/material/IconButton';
import { showLotInformationModal } from '@/components/CallFeedTab/LotInformationModal';
import { Subject } from '@mui/icons-material';
import { HeaderValue } from '@/components/CallFeedTab/PenDropsListHeaderLegacy';
import { Stack } from '@mui/material';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';

export const PenLotToolbar = ({
  penLots,
  value,
  onChange,
}: {
  penLots: Subset<PenLot>[];
  value?: number;
  onChange: (lotId: number | undefined) => void;
}) => {
  const isPhoneSize = useIsPhoneSize();
  const lotsOptions = (penLots ?? []).map((pl) => ({
    label: pl.lot?.name,
    onClick: () => onChange(pl.id!),
  }));
  const selectedPenLot = (penLots ?? []).find((pl) => pl.id === value)!;

  const [sortedLotInfoBlocks, setSortedLotInfoBlocks] = useState<SortableLotInfoBlock[]>([]);
  const [lotInfoBlocksOrderLS] = useLocalStorage<string[]>('lotInfoBlocksOrder', []);

  useEffect(() => {
    setSortedLotInfoBlocks(
      sortableLotInfoBlocks.sort((a, b) => {
        const aIndex = lotInfoBlocksOrderLS.indexOf(a.label);
        const bIndex = lotInfoBlocksOrderLS.indexOf(b.label);
        return aIndex - bIndex;
      })
    );
  }, [lotInfoBlocksOrderLS]);

  // Set default value to first pen lot when penLots are loaded/changed
  useEffect(() => {
    onChange(penLots[0]?.id!);
  }, [onChange, penLots]);

  return (
    <Stack
      direction="row"
      spacing={2}
      display="flex"
      flex="1"
      justifyContent="space-between"
      alignItems="center"
      px={isPhoneSize ? 1 : 2}
      width="100%"
      borderRadius={{ xs: 1, md: 0 }}
      mb={0}
      sx={(theme) => ({ background: theme.palette.grey[300] })}
    >
      <SelectPicker
        items={lotsOptions}
        text={selectedPenLot?.lot?.name}
        sx={{
          color: '#2DA6AE',
          background: '#E2FDFF',
          padding: '1px 7px',
          borderRadius: '7px',
          border: '1px solid rgba(45, 166, 174, 0.7)',
          '&:hover': {
            background: '#D5F9FF',
          },
        }}
      />

      <Box flexGrow={1} overflow="auto" height="46px" display="flex">
        <Box whiteSpace="nowrap" display="flex" gap={4}>
          {sortedLotInfoBlocks.map(({ shortLabel, renderValue }) => (
            <HeaderValue key={shortLabel}>
              {shortLabel}: <b>{selectedPenLot ? renderValue(selectedPenLot as unknown as Subset<PenLot>) : emDash}</b>
            </HeaderValue>
          ))}
        </Box>
      </Box>

      <Box>
        <IconButton
          onClick={() =>
            showLotInformationModal({
              penLots: penLots as unknown as PenLot[],
              defaultPenLotId: value!,
              onSuccess: (penLotId) => onChange(penLotId),
            })
          }
        >
          <Subject />
        </IconButton>
      </Box>
    </Stack>
  );
};

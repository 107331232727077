import { formatNumber, formatTime } from '@/components/helpers/format';
import { emDash } from '@/components/Typography/emDash';
import { GetPenDropDetailsDialogQuery, PenDropAtDate } from '@/web-types';
import { styled } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { sumBy } from 'lodash';
import { isPresent } from 'ts-is-present';
import useLocalStorage from '@/components/hooks/useLocalStorage';
import { useEffect, useMemo, useState } from 'react';
import { bunkScoreLabel } from '@/common/dictionaries/bunkScore';
import { detailedViewColumns } from '@/components/CallFeedTab/constants';
import { formatPercentDoubleDigitPrecision } from '@/common/utils/format';
import { useOrgSettings } from '../OrgSettings/OrgSettings';

const StyledTableCell = styled(TableCell)({
  paddingTop: '8px',
  paddingBottom: '8px',
  whiteSpace: 'nowrap',
});

interface PenDropDetailsTableProps {
  data: PenDropAtDate[];
  /** Rations, in the order they first appear in `data` */
  rations: GetPenDropDetailsDialogQuery['getPenDropDetails']['rations'];
  rationMap: Map<number, GetPenDropDetailsDialogQuery['getPenDropDetails']['rations'][0]>;
}

export const PenDropDetailsTable = ({ data, rations, rationMap }: PenDropDetailsTableProps) => {
  const [{ tz }] = useOrgSettings();
  const rationIndex = new Map(rations.map((r, i) => [r.id, i]));

  const [sortedColumns, setSortedColumns] = useState<string[]>(detailedViewColumns);
  const [detailedViewColumnsOrderLS] = useLocalStorage<string[]>('detailedViewColumnsOrder', []);

  const tableData = useMemo(() => {
    return [
      sortedColumns,
      ...data.map((item) => {
        let result = [] as string[];
        sortedColumns.forEach((column) => {
          switch (column) {
            case 'Date':
              result.push(dayjs(item.date).format('MM/DD'));
              break;
            case 'Head':
              result.push(formatNumber(item.numHeads));
              break;
            case 'Ration':
              const rationNames = item.rationFedPerHead
                .map(({ rationId }) => rationMap.get(rationId))
                .filter(isPresent)
                .sort((a, b) => rationIndex.get(a.id)! - rationIndex.get(b.id)!)
                .map((r) => r.name)
                .join(', ');
              result.push(rationNames);
              break;
            case 'Fed At':
              result.push(formatTime(item.fedOnDate, tz));
              break;
            case 'Bunk Score':
              result.push(item.bunkScore ? bunkScoreLabel(item.bunkScore) : emDash);
              break;
            case 'Call AF':
              result.push(formatNumber(item.calledLbsOnDate, { maximumFractionDigits: 0 }));
              break;
            case 'Change AF':
              let calledLbsChange = '+0';
              if (item.calledLbsChange) {
                calledLbsChange = formatNumber(item.calledLbsChange, { maximumFractionDigits: 0 });
                if (item.calledLbsChange > 0) {
                  calledLbsChange = `+${formatNumber(item.calledLbsChange, { maximumFractionDigits: 0 })}`;
                }
              }
              result.push(calledLbsChange);
              break;
            case 'As Fed':
              result.push(formatNumber(item.fedLbsOnDate));
              break;
            case 'AF/HD':
              result.push(formatNumber(sumBy(item.rationFedPerHead, (r) => r.fedPerHead.asFedLbs)));
              break;
            case 'DMI/HD':
              result.push(formatNumber(sumBy(item.rationFedPerHead, (r) => r.fedPerHead.dryMatterLbs)));
              break;
            case 'Call AF/HD':
              result.push(formatNumber(item.calledPerHead.asFedLbs));
              break;
            case 'Fed AF/HD':
              result.push(formatNumber(item.fedPerHead.asFedLbs));
              break;
            case 'Call DMI/HD':
              result.push(formatNumber(item.calledPerHead.dryMatterLbs));
              break;
            case 'Fed DMI/HD':
              result.push(formatNumber(item.fedPerHead.dryMatterLbs));
              break;
            case 'Est. Weight':
              result.push(formatNumber(Math.round(item.projectedCurrentWeightPerHeadLbs ?? 0)));
              break;
            case 'DM % BW':
              result.push(
                formatPercentDoubleDigitPrecision(
                  item.projectedCurrentWeightPerHeadLbs
                    ? item.fedPerHead.dryMatterLbs / item.projectedCurrentWeightPerHeadLbs
                    : 0
                )
              );
              break;
            case 'Variance AF':
              result.push(formatNumber(item.fedLbsOnDate - item.calledLbsOnDate, { maximumFractionDigits: 0 }));
              break;

            default:
              break;
          }
        });
        return result;
      }),
    ];
  }, [data, rationIndex, rationMap, sortedColumns]);

  useEffect(() => {
    setSortedColumns(
      detailedViewColumns.length === detailedViewColumnsOrderLS?.length
        ? detailedViewColumns.sort((a, b) => {
            const aIndex = detailedViewColumnsOrderLS.indexOf(a);
            const bIndex = detailedViewColumnsOrderLS.indexOf(b);
            return aIndex - bIndex;
          })
        : detailedViewColumns
    );
  }, [detailedViewColumnsOrderLS]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          {tableData[0].map((header, i) => (
            <StyledTableCell key={i}>{header}</StyledTableCell>
          ))}
        </TableHead>
        <TableBody>
          {tableData.slice(1).map((row, i) => (
            <TableRow key={i}>
              {row.map((cell, j) => (
                <StyledTableCell
                  key={j}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: { xs: 'none', md: 120 },
                  }}
                >
                  {cell}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

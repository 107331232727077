import { isEqual } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { SaveResult } from './useCallFeedData';

export type SetWorking<T> = (fn: (prev: T, base: T) => T) => void;

type WorkingState<T> = {
  // the current data that the user is modifying
  working?: T;
  // the saved data that was saved when the user started modifying
  workingBase?: T;
  // the version of the data that is saving
  saving?: T;
};

type ControlsAndFlags<T> = {
  setWorking: SetWorking<T>;
  flags: { dirty: boolean; collision: boolean; saving: boolean };
  saveWorking: () => Promise<SaveResult>;
  resetWorking: () => void;
};

/**
 * Implement the "working" state of savable data.
 * Given data that can be saved to a backend, this function provides a working version of it and other useful tools to deal with dirty, saving, and collision
 * @param saved the data that is currently persisted to the backend
 * @param save a function that will persist data to the backend
 * @param isEqual an optional function to determine if two states are equal
 * @returns
 */
export function useWorking<T>(
  saved: T,
  save: (data: T, prev: T | undefined) => Promise<SaveResult>,
  isEqual: (a: T, b: T) => boolean = statesEqual
): [T, ControlsAndFlags<T>] {
  const [state, setState] = useState<WorkingState<T>>({});
  const { working, workingBase: base, saving } = state;

  // working: the version of the state which the user has modified and is continuing to modify
  // saved: the version of the state which is currently in the database
  // base: the version of the state which was in the database when the user started modifying

  // The difference between `working` and `base` represents this user's intended changes
  // The difference between `saved` and `base` represents changes made by other users since this user started making changes

  const dirty = useMemo(() => !!base && !!working && !isEqual(base, working), [base, working, isEqual]);

  // if there is a collision (someone else has edited this while we still have unsaved changes)
  // ideally we'd merge the user's changes with the other changes, but if this is impractical (or impossible, which it probably is),
  // we should at least show this to the user, and possibly alert the user on an attempted save
  const collision = useMemo(
    () => !!base && dirty && !saving && !isEqual(saved, base),
    [saved, base, dirty, saving, isEqual]
  );
  const flags = useMemo(() => ({ dirty, collision, saving: Boolean(saving) }), [dirty, collision, saving]);
  const saveWorking: () => Promise<SaveResult> = useMemo(() => {
    // if there have been no changes, then there is nothing to save
    if (!working) return () => Promise.resolve('success' as const);
    const versionBeingSaved = working;
    return async () => {
      setState((prev) => ({ ...prev, saving: versionBeingSaved }));
      const status = await save(versionBeingSaved, base);
      if (status === 'success') {
        setState((prev) => ({ working: prev.working, workingBase: versionBeingSaved }));
      } else {
        setState(({ saving, ...prev }) => ({ ...prev }));
      }
      return status;
    };
  }, [working, save, base]);
  const setWorking = useCallback(
    (fn: (data: T, base: T) => T) =>
      setState((prev) => {
        const prevWorking = prev.working ?? saved;
        const workingBase = prev.workingBase ?? saved;
        const working = fn(prevWorking, workingBase);
        if (isEqual(working, workingBase)) {
          // if this happens, that means that this user has no changes (zero delta)
          // so we go back to following the saved state
          return { ...prev, working: undefined, workingBase: undefined };
        }
        return { ...prev, working, workingBase };
      }),
    [setState, saved, isEqual]
  );
  const resetWorking = useCallback(() => setWorking((_, base) => base), [setWorking]);
  return [
    working ?? saved,
    {
      setWorking,
      flags,
      saveWorking,
      resetWorking,
    },
  ];
}

const statesEqual = <T>(a: T, b: T) => isEqual(a, b);

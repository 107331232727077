import { BunkScoreValue, GetFeedingPageRationsQuery, LoadStage, LotSex } from '@/web-types';
import { Control, UseFormReturn } from 'react-hook-form';
import type { localdate } from '@/common/models/types';

export type RationType = GetFeedingPageRationsQuery['getRations'][0];

export interface PenDrop {
  _uuid?: string;
  id?: number;
  date: localdate;
  rationId?: number;
  rationRevision?: number;
  calledLbs?: number;
  lastOver0CalledLbs?: number;
  fedLbs?: number;
  penId: number;
  numHeads: number;
  load?: null | {
    stage: LoadStage;
  };
}

export enum CallBy {
  DMI_PER_HEAD = 'DMI_PER_HEAD',
  AS_FED_PER_HEAD = 'AS_FED_PER_HEAD',
}

type PickedPenLot = {
  id: number;
  penId: number;
  fromDate: Date;
  toDate: Date | null;
  numHeads: number;
  lot?: {
    id: number;
    name: string;
    sex?: LotSex | null;
    inWeightLbs?: number | null;
    projectedCurrentWeightLbs?: number | null;
    daysOnFeed?: number | null;
  } | null;
};

export interface PenFeed {
  id: number;
  name: string;
  numHeads: number;
  bunkScore?: BunkScoreValue | '' | null;
  totalWeight?: number | null;
  dmiPerHead?: number | null;
  asFedPerHead?: number | null;
  projectedCurrentWeightPerHeadLbs?: number | null;
  selectedAllRationId?: number | null;
  drops?: PenDrop[];
  tomorrowDrops?: PenDrop[];
  /** The pen lots for the pen at the end of yesterday (23:59:59) */
  penLotsEndOfYesterday?: PickedPenLot[] | null;
  penLots?: PickedPenLot[] | null;
}

export interface CalLFeedFormValues {
  pens: PenFeed[];
  detailDialogPenIndex: number | null;
  callBy: CallBy;
  graphShowDmi: boolean;
}

export interface PenDropsListPropsLegacy {
  pens: PenFeed[];
  penIndex: number;
  rations: RationType[];
  rationIngredientsMap: Map<number, RationType['ingredients']>;
  inDialog?: boolean;
  isTodaySelected?: boolean;
  selectedDate: localdate;
  showDetailModal: (penId: number) => void;
  formMethods: UseFormReturn<CalLFeedFormValues>;
  formRef?: React.RefObject<HTMLFormElement>;
  onDateSelected?: (date: localdate) => void;
}

export interface PenDropEditorProps {
  dropIndex: number;
  penIndex: number;
  rations: RationType[];
  control: Control<CalLFeedFormValues>;
  forceEnabled?: boolean;
  onDeleteDrop?: (dropIndex: number) => void;
  onRationChange?: (e: any) => void;
  onCalledLbsChange?: (dropIndex: number, value: number) => void;
  dropId: number | string;
  disabled?: boolean;
  inDialog?: boolean;
}

export type CallFeedTabPropsLegacy = {
  canDuplicateYesterdayDrops?: boolean;
  pens?: PenFeed[];
  rationsLegacy?: RationType[];
  dateOptions: {
    value: localdate;
    label: string;
  }[];
  selectedDate: localdate;
  stale: boolean;
  onDateSelected: (date: localdate) => void;
  onSubmit?: () => void;
  didDuplicate: () => void;
};

export interface DuplicateDropsEmptyViewProps {
  isTodaySelected?: boolean;
  isFullScreen?: boolean;
  onSuccess?: () => void;
  fromDate: localdate;
  toDate: localdate;
}

export interface CallFeedUIState {
  callBy: CallBy;
  /** If true, show DMI in the graph. Else show As Fed */
  graphShowDmi: boolean;
}

import { useDuplicateDropsAndLoadsMutation } from '@/web-types';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import { useCallback } from 'react';
import { EmptyScreen } from '@/components/EmptyScreen';
import Typography from '@mui/material/Typography';
import { DuplicateDropsEmptyViewProps } from './types';
import { enqueueSnackbar } from 'notistack';

const TOMORROW_TEXT = {
  title: 'To call feed and build loads for tomorrow, press the duplicate button.',
  subtitle: `This will copy today's feed calls and loads to tomorrow for all pens.`,
  footer: `Remember: After pressing Duplicate, changes to tomorrow's feed calls and loads won't affect today's, and vice versa.`,
};
const TODAY_TEXT = {
  title: 'To call feed and build loads, press the duplicate button.',
  subtitle: `This will copy yesterday's feed calls and loads.`,
  footer: '',
};

export const DuplicateDropsEmptyView = ({
  isTodaySelected = false,
  isFullScreen = true,
  fromDate,
  toDate,
  onSuccess,
}: DuplicateDropsEmptyViewProps): JSX.Element => {
  const [{ fetching: duplicating }, duplicateDropsAndLoads] = useDuplicateDropsAndLoadsMutation();

  const TEXT = isTodaySelected ? TODAY_TEXT : TOMORROW_TEXT;

  const onDuplicateClick = useCallback(async () => {
    const { error } = await duplicateDropsAndLoads({ fromDate, toDate }, { additionalTypenames: ['Drop', 'Load'] });
    if (error) return;

    enqueueSnackbar('Successfully duplicated', { variant: 'success' });
    onSuccess?.();
  }, [duplicateDropsAndLoads, onSuccess, fromDate, toDate]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        py: 3,
        height: isFullScreen ? 'calc(100vh - 300px)' : 'auto',
      }}
    >
      <EmptyScreen useImage={false}>
        <Box sx={{ mt: 1, mb: 3, textAlign: 'center', maxWidth: '686px', px: 2 }}>
          <Typography variant="h2">{TEXT.title}</Typography>
          <Typography sx={{ mb: 1 }}>{TEXT.subtitle}</Typography>
          <Typography sx={{ fontStyle: 'italic' }}>{TEXT.footer}</Typography>
        </Box>
        <LoadingButton
          loading={duplicating}
          data-testid={isFullScreen ? `feeding-duplicate-button` : `feeding-duplicate-button-2`}
          onClick={onDuplicateClick}
        >
          Duplicate
        </LoadingButton>
      </EmptyScreen>
    </Box>
  );
};

import { Box, Button, Stack, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';
import { PercentsInput } from '../controls/PercentsInput';

type DistributionEditorProps = {
  distributionPercents: { value: readonly number[]; onChange: (value: readonly number[]) => void };
  onResetOverrides?: () => void;
  onClose: () => void;
};
export const DistributionEditor = React.memo(
  ({ distributionPercents, onResetOverrides, onClose }: DistributionEditorProps) => {
    const [state, setState] = useState(() => distributionPercents.value);
    const [resetState, setResetState] = useState(onResetOverrides ? true : false);
    return (
      <>
        <Typography mb={2}>
          This shows the number of drops and their distribution that recur in this pen each day.
        </Typography>

        <Box mt={3} mb={2}>
          <PercentsInput value={state} onChange={setState} />
        </Box>

        {onResetOverrides && (
          <Stack m={3} direction="row" alignItems="baseline" justifyContent="center" gap={1}>
            <Typography>Redistribute the current calls in this pen to match this distribution.</Typography>
            <Switch
              data-testid="distribution-reset-overrides-switch"
              checked={resetState}
              onChange={(e) => setResetState(e.target.checked)}
            />
          </Stack>
        )}

        <Stack direction="row" gap={2} mt={4} style={{ justifyContent: 'center' }}>
          <Button data-testid="distribution-cancel-button" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            data-testid="distribution-save-button"
            onClick={() => {
              if (resetState && onResetOverrides) {
                onResetOverrides();
              }
              distributionPercents.onChange(state);
              onClose();
            }}
          >
            Save
          </Button>
        </Stack>
      </>
    );
  }
);

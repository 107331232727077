import { Button, Dialog, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import { useIsPhoneSize } from '../../hooks/useResponsive';
import { DialogContainer, DialogTitle } from '@/components/Dialog';
import { DistributionEditor } from './DistributionEditor';

type SimpleViewEditDropsButton = {
  defaultRationId: number;
  onAddRecurringDrop: ((rationId: number) => void) | undefined;
  onAddOneTimeDrop: ((rationId: number) => void) | undefined;
  onResetOverrides?: () => void;
  distributionPercents: { value: readonly number[]; onChange: (value: readonly number[]) => void } | undefined;
};
export const SimpleViewEditDropsButton: React.FC<SimpleViewEditDropsButton> = React.memo(
  ({ defaultRationId, onAddRecurringDrop, onAddOneTimeDrop, distributionPercents, onResetOverrides }) => {
    const [mode, setMode] = useState<'closed' | 'prompt' | 'edit'>('closed');
    const isPhoneSize = useIsPhoneSize();

    return (
      <>
        <Button
          data-testid="call-feed-simple-add-drop-button"
          variant="outlined"
          onClick={() => {
            // if we can edit distributions but not add a one-time, then go straight to edit mode, otherwise prompt
            setMode(distributionPercents?.onChange && !onAddOneTimeDrop ? 'edit' : 'prompt');
          }}
          style={{
            padding: 0,
            minWidth: 64,
            width: 64,
            height: 40,
            borderWidth: 1,
            borderStyle: 'dashed',
            borderRadius: 4,
            borderColor: '#AEB5BC',
          }}
        >
          {distributionPercents ? <TuneIcon /> : <AddIcon />}
        </Button>
        <Dialog
          data-testid="edit-drops-dialog"
          onClose={() => setMode('prompt')}
          open={mode !== 'closed'}
          maxWidth="xs"
          fullWidth
          fullScreen={isPhoneSize}
        >
          {mode !== 'closed' ? (
            <DialogContainer>
              <DialogTitle
                title={mode === 'prompt' ? `Edit Drops` : `Daily Drop Distribution`}
                onCloseClick={() => setMode('closed')}
                containerProps={{
                  sx: {
                    pb: isPhoneSize ? 1 : 3,
                  },
                }}
              />
              {mode === 'prompt' ? (
                <>
                  <Typography mb={2}>
                    {distributionPercents && `Edit Drop Distribution (Number and distribution of drops per pen) `}
                    {onAddRecurringDrop && `Add a recurring drop `}
                    {` or add a 1 time drop (1 time drops do not show up in the pen tomorrow)`}
                  </Typography>
                  <Stack direction="row" gap={2} mt={4} style={{ justifyContent: 'center' }}>
                    {onAddRecurringDrop ? (
                      <Button
                        data-testid="call-feed-simple-add-recurring-drop-button"
                        onClick={() => {
                          onAddRecurringDrop!(defaultRationId);
                          setMode('closed');
                        }}
                      >
                        Add Recurring Drop
                      </Button>
                    ) : null}
                    {distributionPercents ? (
                      <Button
                        data-testid="call-feed-simple-edit-drop-distribution-button"
                        onClick={() => {
                          setMode('edit');
                        }}
                      >
                        Edit Drop Distribution
                      </Button>
                    ) : null}
                    <Button
                      data-testid="call-feed-simple-add-one-time-drop-button"
                      variant="outlined"
                      onClick={() => {
                        onAddOneTimeDrop!(defaultRationId);
                        setMode('closed');
                      }}
                    >
                      Add 1 Time Drop
                    </Button>
                  </Stack>
                </>
              ) : mode === 'edit' ? (
                <>
                  <DistributionEditor
                    distributionPercents={distributionPercents!}
                    onClose={() => setMode('closed')}
                    onResetOverrides={onResetOverrides}
                  />
                </>
              ) : null}
            </DialogContainer>
          ) : null}
        </Dialog>
      </>
    );
  }
);

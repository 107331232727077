import {
  PenDropsListHeaderBunkScoreText,
  PenDropsListHeaderInfo,
  PenDropsListHeaderStatText,
} from '@/components/CallFeedTab/styledComponents';
import Typography from '@mui/material/Typography';
import { LinkButton } from '@/components/LinkButton/LinkButton';
import { InfoOutlined } from '@mui/icons-material';
import { showLotsInPenModal } from '@/components/CallFeedTab/LotsInPenModal';
import { Button } from '@mui/material';
import { showPenDropDetailsModalLegacy } from '@/components/CallFeedTab/PenDropDetailsModalLegacy';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { CalLFeedFormValues, PenFeed, RationType } from '@/components/CallFeedTab/types';
import type { localdate } from '@/common/models/types';
import { Control, UseFormReturn } from 'react-hook-form';
import { BunkScoreSelectField } from '@/components/CallFeedTab/BunkScoreSelectField';
import { useMemo } from 'react';
import { formatProjectedWeightLbs } from '@/components/helpers/format';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { showAlertModal } from '../AlertModal/AlertModal';
import { PlanCallMethod } from '@/web-types';
import { useOrgSettings } from '@/components/OrgSettings/OrgSettings';
import { useCallFeedFeatureFlags } from '../CallFeed/hooks/useCallFeedFeatureFlags';

export const Header = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  height: '46px',
  background: theme.palette.grey[300],
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));
Header.defaultProps = {
  direction: 'row',
  spacing: 2,
};

export const PenDropsListHeaderLegacy = ({
  pen,
  pens,
  penIndex,
  rations,
  rationIngredientsMap,
  selectedDate,
  isTodaySelected,
  showDetailModal,
  formMethods,
  control,
  formRef,
}: {
  pen: PenFeed;
  pens: PenFeed[];
  penIndex: number;
  rations: RationType[];
  rationIngredientsMap: Map<number, RationType['ingredients']>;
  isTodaySelected?: boolean;
  selectedDate: localdate;
  showDetailModal: (penId: number) => void;
  formMethods: UseFormReturn<CalLFeedFormValues>;
  control: Control<CalLFeedFormValues, any>;
  formRef?: React.RefObject<HTMLFormElement>;
}) => {
  const { useCallFeedV2DetailedMode } = useCallFeedFeatureFlags();
  const [{ settings }] = useOrgSettings();
  const { 'feeding.primaryCallMethod': settingCallFeedPrimaryCallMethod } = settings;

  const isDirty = formMethods.formState.isDirty;

  const isPhoneSize = useIsPhoneSize();
  const headerText = useMemo(() => {
    const result = [`${pen.numHeads ?? 0} Head`];
    if (pen.projectedCurrentWeightPerHeadLbs) {
      result.push(formatProjectedWeightLbs(pen.projectedCurrentWeightPerHeadLbs ?? 0, true, false));
    }
    if (isPhoneSize) result.length = 1;

    return result.join(' ｜ ');
  }, [isPhoneSize, pen.numHeads, pen.projectedCurrentWeightPerHeadLbs]);

  const isCallFeedV2ByDistribution =
    useCallFeedV2DetailedMode && settingCallFeedPrimaryCallMethod.method === PlanCallMethod.ByDistribution;

  return (
    <Header>
      <PenDropsListHeaderInfo flex={1}>
        <Box
          sx={{
            width: { xs: 'auto', md: 180 },
            color: 'primary.main',
            fontWeight: 600,
          }}
        >
          {pen.name}
        </Box>
        {!isPhoneSize && !isCallFeedV2ByDistribution && (
          <PenDropsListHeaderBunkScoreText data-testid="call-feed-pen-bunkScore-text">
            <BunkScoreSelectField control={control} penIndex={penIndex} isTodaySelected={isTodaySelected} />
          </PenDropsListHeaderBunkScoreText>
        )}
        <PenDropsListHeaderStatText data-testid="call-feed-pen-stat-text" flex={1}>
          <Typography sx={{ mr: 1 }}>{headerText} ｜</Typography>
          <LinkButton endIcon={<InfoOutlined />} onClick={() => showLotsInPenModal({ penId: pen.id })}>
            Lots
          </LinkButton>
        </PenDropsListHeaderStatText>

        {(useCallFeedV2DetailedMode || !isPhoneSize) && (
          <Button
            variant="text"
            data-testid="call-feed-pen-show-details"
            onClick={() => {
              if (useCallFeedV2DetailedMode) {
                if (isDirty) {
                  // Alert that you must save before switching to detailed view
                  return showAlertModal({
                    title: 'Unsaved Changes',
                    type: 'warning',
                    modalSize: 'sm',
                    okButton: 'OK',
                    message: 'You must save before switching to detailed view.',
                    onOk: () => {},
                  });
                }
                showDetailModal(pen.id);
                return;
              }
              return showPenDropDetailsModalLegacy({
                pens,
                penIndex,
                rations,
                rationIngredientsMap,
                selectedDate,
                isTodaySelected,
                formMethods,
                formRef,
              });
            }}
          >
            Show Details
          </Button>
        )}
      </PenDropsListHeaderInfo>
    </Header>
  );
};

export const HeaderValue = styled(Box)(({ theme }) => ({
  color: theme.palette.customGrey.main,
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  fontSize: [11, 13],
  b: {
    color: 'black',
    display: 'inline-block',
    fontSize: [13, 15],
  },
}));

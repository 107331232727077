import { AccordionDetails, Box, BoxProps, Grid, styled } from '@mui/material';
import { IncrementalNumberInput } from '@/components/CallFeed/controls/IncrementalNumberInput';
import { CallFeedDayUiState } from '@/components/CallFeed/hooks/useCallFeedUiState';
import { formatNumber, formatPercentDoubleDigitPrecision } from '@/common/utils/format';
import { isNumber } from 'lodash';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import * as React from 'react';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import useLocalStorage from '@/components/hooks/useLocalStorage';
import { InfoOutlined } from '@mui/icons-material';
import { LinkButton } from '../LinkButton/LinkButton';
import { showAlertModal } from '../AlertModal/AlertModal';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `none`,
    '&:not(:last-child)': { borderBottom: 0 },
    '&::before': { display: 'none' },
  })
);
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  padding: '3px 10px',
  background: theme.palette.grey[300],
}));

const CellTitle = styled(Grid)({
  fontWeight: 'bold',
});
const CellValue = styled(Grid)({
  textAlign: 'center',
});

interface FeedPlanBoxProps {
  currentDay: CallFeedDayUiState;
  day: string;
  increments: { dmiPerHead: number; afPerHead: number };
}

export const FeedPlanBox = (props: FeedPlanBoxProps) => {
  const isPhoneSize = useIsPhoneSize();
  const [feedPlanBoxOpen, setFeedPlanBoxOpen] = useLocalStorage<boolean>('feedPlanBoxOpen', false);
  if (!isPhoneSize) {
    return <FeedPlanBoxBody {...props} />;
  }

  return (
    <Accordion expanded={feedPlanBoxOpen} onChange={() => setFeedPlanBoxOpen(!feedPlanBoxOpen)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Changes for the day</AccordionSummary>
      <AccordionDetails sx={(theme) => ({ background: theme.palette.grey[100], padding: '10px 0 0', border: 'none' })}>
        <FeedPlanBoxBody {...props} />
      </AccordionDetails>
    </Accordion>
  );
};

const FeedPlanBoxBody = ({ day, currentDay, increments }: FeedPlanBoxProps) => {
  return (
    <Grid container rowGap={{ xs: 1, md: 2 }} sx={{ width: '100%', alignItems: 'center' }}>
      {day === 'today' ? (
        <>
          <CellTitle
            item
            xs={12}
            md={3}
            sx={{
              background: (theme) => ({ xs: theme.palette.grey[300], md: 'transparent' }),
              padding: `2px 8px`,
            }}
          >
            AF
          </CellTitle>
          <CellValue
            item
            xs={6}
            md={4}
            data-testid="as-fed-current"
            sx={{ textAlign: { xs: 'left', md: 'center' } }}
            px={1}
          >
            {formatNumber(currentDay.afLbs.value)}
          </CellValue>
          <CellValue item xs={3} md={3} data-testid="as-fed-difference">
            <DailyChange value={currentDay.afLbsDayDifference} />
          </CellValue>
          <CellValue item xs={3} md={2} data-testid="as-fed-previous-day">
            {formatNumber(currentDay.afLbsPreviousDay)}
          </CellValue>

          <CellTitle
            item
            xs={12}
            md={3}
            sx={{
              background: (theme) => ({ xs: theme.palette.grey[300], md: 'transparent' }),
              padding: `2px 8px`,
            }}
          >
            AF/Head
          </CellTitle>
          <CellValue item xs={6} md={4}>
            <IncrementalNumberInput
              data-testid="af-per-head-current"
              increment={increments.afPerHead}
              {...currentDay.afLbsPerHead}
            />
          </CellValue>
          <CellValue item xs={3} md={3} data-testid="af-per-head-difference">
            <DailyChange value={currentDay.afLbsPerHeadDayDifference} />
          </CellValue>
          <CellValue item xs={3} md={2} data-testid="af-per-head-previous-day">
            {formatNumber(currentDay.afLbsPerHeadPreviousDay)}
          </CellValue>

          <CellTitle
            item
            xs={12}
            md={3}
            sx={{
              background: (theme) => ({ xs: theme.palette.grey[300], md: 'transparent' }),
              padding: `2px 8px`,
            }}
          >
            DMI/Head
          </CellTitle>
          <CellValue item xs={6} md={4}>
            <IncrementalNumberInput
              data-testid="dmi-per-head-current"
              increment={increments.dmiPerHead}
              {...currentDay.dmiLbsPerHead}
            />
          </CellValue>
          <CellValue item xs={3} md={3} data-testid="dmi-per-head-difference">
            <DailyChange value={currentDay.dmiLbsPerHeadDayDifference} />
          </CellValue>
          <CellValue item xs={3} md={2} data-testid="dmi-per-head-previous-day">
            {formatNumber(currentDay.dmiLbsPerHeadPreviousDay)}
          </CellValue>

          <CellTitle
            item
            xs={12}
            md={3}
            sx={{
              background: (theme) => ({ xs: theme.palette.grey[300], md: 'transparent' }),
              padding: `2px 8px`,
            }}
          >
            DM % BW
          </CellTitle>
          {currentDay.dmAsPercentOfBW == null ? (
            <CellValue
              item
              xs={12}
              md={9}
              data-testid="dm-percent-bw-current"
              sx={{ textAlign: { xs: 'left', lg: 'left' } }}
              px={1}
            >
              <LinkButton
                type="button"
                variant="text"
                endIcon={<InfoOutlined />}
                onClick={() => {
                  return showAlertModal({
                    title: 'Missing Estimated Weight',
                    type: 'info',
                    modalSize: 'sm',
                    okButton: 'OK',
                    message:
                      'Cannot calculate percent of bodyweight because at least one of the lots in this pen does not have an estimated weight',
                    onOk: () => {},
                  });
                }}
              >
                <i>missing weight data</i>
              </LinkButton>
            </CellValue>
          ) : (
            <>
              <CellValue
                item
                xs={6}
                md={4}
                data-testid="dm-percent-bw-current"
                sx={{ textAlign: { xs: 'left', md: 'center' } }}
                px={1}
              >
                {formatPercentDoubleDigitPrecision(currentDay.dmAsPercentOfBW)}
              </CellValue>
              <CellValue item xs={3} md={3} data-testid="dm-percent-bw-difference">
                <DailyChange value={currentDay.dmAsPercentOfBWDayDifference} usePercentFormatted />
              </CellValue>
              <CellValue item xs={3} md={2} data-testid="dm-percent-bw-previous-day">
                {formatPercentDoubleDigitPrecision(currentDay.dmAsPercentOfBWPreviousDay!)}
              </CellValue>
            </>
          )}
        </>
      ) : (
        <>
          <CellTitle
            item
            xs={12}
            md={3}
            sx={{
              background: (theme) => ({ xs: theme.palette.grey[300], md: 'transparent' }),
              padding: `2px 8px`,
            }}
          >
            AF
          </CellTitle>
          <CellValue
            item
            xs={6}
            md={5}
            data-testid="as-fed-current"
            sx={{ textAlign: { xs: 'left', md: 'center' } }}
            px={1}
          >
            {formatNumber(currentDay.afLbs.value)}
          </CellValue>
          <Grid item xs={6} md={4} />

          <CellTitle
            item
            xs={12}
            md={3}
            sx={{
              background: (theme) => ({ xs: theme.palette.grey[300], md: 'transparent' }),
              padding: `2px 8px`,
            }}
          >
            AF/Head
          </CellTitle>
          <CellValue item xs={6} md={5}>
            <IncrementalNumberInput
              data-testid="af-per-head-current"
              increment={increments.afPerHead}
              {...currentDay.afLbsPerHead}
            />
          </CellValue>
          <Grid item xs={3} md={4} />

          <CellTitle
            item
            xs={12}
            md={3}
            sx={{
              background: (theme) => ({ xs: theme.palette.grey[300], md: 'transparent' }),
              padding: `2px 8px`,
            }}
          >
            DMI/Head
          </CellTitle>
          <CellValue item xs={6} md={5}>
            <IncrementalNumberInput
              data-testid="dmi-per-head-current"
              increment={increments.dmiPerHead}
              {...currentDay.dmiLbsPerHead}
            />
          </CellValue>
          <Grid item xs={3} md={4} />

          {isNumber(currentDay.dmAsPercentOfBW) && (
            <>
              <CellTitle
                item
                xs={12}
                md={3}
                sx={{
                  background: (theme) => ({ xs: theme.palette.grey[300], md: 'transparent' }),
                  padding: `2px 8px`,
                }}
              >
                DM % BW
              </CellTitle>
              <CellValue
                item
                xs={6}
                md={5}
                data-testid="dm-percent-bw-current"
                sx={{ textAlign: { xs: 'left', md: 'center' } }}
                px={1}
              >
                {formatPercentDoubleDigitPrecision(currentDay.dmAsPercentOfBW!)}
              </CellValue>
              <Grid item xs={3} md={4} />
            </>
          )}
        </>
      )}
    </Grid>
  );
};

interface BoxPropsWithDataTestId extends BoxProps {
  'data-testid'?: string;
}

const DailyChange = ({
  value,
  usePercentFormatted,
  ...boxProps
}: {
  value: number;
  usePercentFormatted?: boolean;
  boxProps?: BoxPropsWithDataTestId;
}) => {
  // We need to round before detection of positive/negative because otherwise you end up with `-0` when the value is `-0.0001`
  value = Math.round(value * 10000) / 10000 + 0; // `+ 0` converts javascript's -0 to 0.
  const isPositive = value >= 0;
  const formattedValue = usePercentFormatted ? formatPercentDoubleDigitPrecision(value) : formatNumber(value);
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isPositive ? 'rgba(0, 200, 83, 0.15)' : 'rgba(244, 67, 54, 0.15)',
        color: (theme) => (isPositive ? theme.palette.green[700] : theme.palette.red[700]),
        fontSize: '12px',
        padding: '1px 8px',
        margin: '0 10px',
        borderRadius: '8px',
        minWidth: '60px',
      }}
      {...boxProps}
    >
      {isPositive ? `+${formattedValue}` : formattedValue}
    </Box>
  );
};

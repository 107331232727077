import { useOrgSettings } from '../OrgSettings/OrgSettings';
import { useMemo } from 'react';
import { CallFeedPlanQuery, useCallFeedPlanQuery, useCallFeedSavePlansMutation } from '@/web-types';
import { useRations } from './hooks/useRations';
import { Day } from './callPlan';
import { addDays, getEndOfDayDayjs } from '@/common/utils/date';
import React from 'react';
import { EmptyFeedingScreen } from '../BuildLoadsTab/EmptyFeedingScreen';
import { localdate } from '@/common/models/types';
import { usePolling } from '../hooks/usePolling';
import { LoadingScreen } from '../LoadingScreen';
import gql from 'graphql-tag';
import { CallFeedTabView } from './CallFeedTabView';

gql`
  query CallFeedPlan($yesterday: LocalDate!, $tomorrow: LocalDate!, $endOfYesterdayDate: DateTime!) {
    getPens {
      id
      name
      numHeads
      currentWeightPerHeadLbs: projectedCurrentWeightPerHeadLbs
      yesterdayWeightPerHeadLbs: projectedCurrentWeightPerHeadLbs(periodEnd: $yesterday)
      bunkScores {
        date
        bunkScore
      }
      numHeadsEndOfYesterday: numHeads(instant: $endOfYesterdayDate)
      drops(fromDate: $yesterday, toDate: $tomorrow, includeBatchDrops: false) {
        id
        penId
        date
        calledLbs
        penDropIndex
        actualCalledLbs
        fedLbs
        fedOn
        planCallAmount
        planCallUnit
        planDayOverride
        rationId
        numHeads
        dryMatterRatio
        loadId
        load {
          stage
        }
        children {
          loadId
        }
      }
      # TODO: consider loading these asynchronously since this is not needed to display the simple UI
      activePenLots {
        id
        numHeads
        fromDate
        toDate
        lotId
        penId
        lot {
          name
          id
          sex
          processedOn
          estimatedSaleWeightPerHeadLbs
          estimatedAverageDailyGain
          numHeadsIn
          inWeightLbs
          projectedCurrentWeightPerHeadLbs
          deathLossCount
          numHeadsTreated
          totalSalesNumHeads
          daysOnFeed
          averageDailyGain
          sources
          lotOwners {
            contact {
              name
            }
          }
        }
      }
    }
  }
  mutation CallFeedSavePlans($pens: [CallFeedPenInput!]!) {
    saveCallFeedPens(pens: $pens) {
      penId
      drops {
        id
        date
        calledLbs
        penDropIndex
        actualCalledLbs
        fedLbs
        fedOn
        planCallAmount
        planCallUnit
        planDayOverride
        rationId
        numHeads
        dryMatterRatio
      }
    }
  }
`;

export type DbDrop = NonNullable<DbPen['drops']>[number];
export type DbPen = CallFeedPlanQuery['getPens'][number];
export type SavePenDropsAndObservations = ReturnType<typeof useCallFeedSavePlansMutation>[1];

// TODO: remove this when we no longer need legacy mode
/** Legacy mode is when we show old simple view with new detail view */
export type LegacyMode = {
  penId: number | undefined;
  onChangePen: (penId: number | undefined) => void;
};

export type CallFeedTabProps = {
  today: localdate;
  day: Day;
  setDay: (day: Day) => void;
  didDuplicate: () => void;
  legacyMode?: LegacyMode;
};
export const CallFeedTab: React.FC<CallFeedTabProps> = ({ today, day, setDay, didDuplicate, legacyMode }) => {
  // This is the component which fetches the data
  // It takes care of waiting until all the data is loaded and rendering a loading indicator until then
  // This significantly simplifies the hooks in the "view" component because they don't have to work around nulls
  const [{ loaded: settingsLoaded, settings: orgSettings, tz }] = useOrgSettings();
  const { rations, rationIdToDmRatio } = useRations();
  const yesterday = addDays(today, -1);
  const tomorrow = addDays(today, 1);
  const endOfYesterdayDayjs = getEndOfDayDayjs(yesterday, tz);

  // Raw query and mutations from/to GraphQL (drops)
  const [{ data, fetching, operation }, refetch] = useCallFeedPlanQuery({
    variables: { yesterday, tomorrow, endOfYesterdayDate: endOfYesterdayDayjs.toISOString() },
  });
  usePolling({ intervalMs: 30_000, enabled: true, refetches: [refetch] });
  const [{ fetching: saving }, callFeedSavePlan] = useCallFeedSavePlansMutation();

  const allPens = data?.getPens;
  const pens = useMemo(
    () =>
      allPens?.filter(
        (p) =>
          (p.numHeads ?? 0) > 0 ||
          p.drops?.some((d) => d.date === today && (d.loadId || d.children?.some((c) => c.loadId)))
      ),
    [allPens, today]
  );

  if (fetching || !settingsLoaded || !pens || !rations || !rationIdToDmRatio || !operation) {
    return <LoadingScreen />;
  }

  if (pens.length === 0) {
    // TODO: I think this message is technically wrong. It's possible there are just no populated pens
    return <EmptyFeedingScreen mainText="There are currently no Pens" subText="Create pens to get started" />;
  }

  const { method, unit } = orgSettings['feeding.primaryCallMethod'];

  // at this point the data is non-nullable
  return (
    <CallFeedTabView
      // if 'today' changes and this component must discard its internal state, othwerise the working state will be for day X while components display day Y
      key={today}
      pens={pens}
      today={today}
      callUnit={unit}
      callMethod={method}
      save={callFeedSavePlan}
      saving={saving}
      rations={rations}
      rationIdToDmRatio={rationIdToDmRatio}
      day={day}
      setDay={setDay}
      didDuplicate={didDuplicate}
      settings={orgSettings}
      legacyMode={legacyMode}
    />
  );
};

import { Box, Button, IconButton } from '@mui/material';
import { SaveWorking, SetWorking } from './hooks/useCallFeedData';
import { RationToDryMatterRatio } from '@/common/models/ration';
import { FeedingMethod } from '@/web-types';
import { RationOption } from './hooks/useRations';
import { Day } from './callPlan';
import { TodayTomorrowStack } from './TodayTomorrowStack';
import { showCallFeedSettingsModal } from '../CallFeedTab/CallFeedSettingsModal';
import SettingsIcon from '@mui/icons-material/Settings';
import { Virtuoso } from 'react-virtuoso';
import React, { useCallback } from 'react';
import { CallFeedSinglePenSimpleView } from './CallFeedSinglePenSimpleView';
import { CallFeedPen } from './CallFeedTabView';
import { UnsavedFlashMessage } from '../UnsavedFlashMessage';
import { SimpleViewHeaderContainer } from './SimpleViewHeaderContainer';

type CallFeedSimpleViewProps = {
  day: Day;
  setDay: (day: Day) => void;
  dirty: boolean;
  clearChanges: () => void;
  saveWorking: SaveWorking;
  pens: CallFeedPen[];
  rationIdToDmRatio: RationToDryMatterRatio;
  rations: readonly RationOption[];
  feedingMethod: FeedingMethod;
  increments: { dmiPerHead: number; afPerHead: number; af: number };
};
export const CallFeedSimpleView: React.FC<CallFeedSimpleViewProps> = ({
  day,
  setDay,
  dirty,
  clearChanges,
  saveWorking,
  pens,
  rationIdToDmRatio,
  rations,
  feedingMethod,
  increments,
}) => {
  return (
    <>
      <SimpleViewHeaderContainer>
        <TodayTomorrowStack data-testid="call-feed-simple-day-select" value={day} onChange={setDay} />
        <Box
          flex={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ width: { xs: '100%', sm: 'auto' }, textAlign: 'right', whiteSpace: 'nowrap' }}
        >
          <Box flex={1} mr={1}>
            {dirty ? <UnsavedFlashMessage /> : null}
          </Box>
          <IconButton data-testid="call-feed-settings-button" onClick={() => showCallFeedSettingsModal({})}>
            <SettingsIcon />
          </IconButton>
          <Button data-testid="call-feed-tab-cancel-button" variant="text" disabled={!dirty} onClick={clearChanges}>
            Cancel
          </Button>
          <Button data-testid="call-feed-tab-save-button" disabled={!dirty} onClick={saveWorking}>
            Save
          </Button>
        </Box>
      </SimpleViewHeaderContainer>
      <Virtuoso
        style={{ flex: 1 }}
        data={pens}
        overscan={10}
        itemContent={(index, { penId, penData, workingData, setWorking, showDetailedView }) => {
          return (
            <CallFeedSinglePenSimpleView
              key={penId}
              penId={penId}
              penData={penData}
              workingData={workingData}
              rationIdToDmRatio={rationIdToDmRatio}
              rations={rations}
              setWorkingData={setWorking}
              feedingMethod={feedingMethod}
              day={day}
              increments={increments}
              showDetailedView={showDetailedView}
            />
          );
        }}
      />
    </>
  );
};

import { getCalledLbsFromDmiPerHead, getDmiPerHeadByDrops, twoDecimals } from '@/common/utils/math';
import { PenDropEditor } from '@/components/CallFeedTab/PenDropEditor';
import {
  AddDropButton,
  AddDropButtonWrap,
  BoxFlexOne,
  FieldsStack,
  PenDropEditorContainer,
  PenDropEditorContainerDropText,
  PenDropEditorContainerHeader,
  PenDropsListContainer,
  PenDropsListDropsContainer,
  PenDropsListDropsScroller,
  PenDropsListDropsStack,
  PenDropsListHeaderBunkScoreText,
  StackFlexOne,
  TotalCallAsFedBox,
} from '@/components/CallFeedTab/styledComponents';
import { CallBy, PenDrop, PenDropsListPropsLegacy as PenDropsListPropsLegacy } from '@/components/CallFeedTab/types';
import { ControlledNumberFieldWithIncrements } from '@/components/ControlledNumberField';
import ControlledSelectField from '@/components/ControlledSelectField';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { Drop, LoadStage, PlanCallMethod } from '@/web-types';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, MenuItem, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { callFeedKvStore } from './callFeedKvStore';
import { sumBy } from 'lodash';
import { showConfirmationModal } from '@/components/ConfirmationModal/ConfirmationModal';
import { useOrgSettings } from '@/components/OrgSettings/OrgSettings';
import { BunkScoreSelectField } from '@/components/CallFeedTab/BunkScoreSelectField';
import { PenDropsListHeaderLegacy } from '@/components/CallFeedTab/PenDropsListHeaderLegacy';
import { getRationCurrentRevisionToDryMatterRatio } from '@/common/models/ration';
import { useCallFeedFeatureFlags } from '../CallFeed/hooks/useCallFeedFeatureFlags';

const Empty = [] as never[];

export const PenDropsListLegacy = ({
  pens,
  penIndex,
  rations,
  rationIngredientsMap,
  inDialog = false,
  isTodaySelected,
  selectedDate,
  showDetailModal,
  formMethods,
  formRef,
}: PenDropsListPropsLegacy): JSX.Element => {
  const pen = pens[penIndex];
  const { control, getValues, setValue } = formMethods;
  const isPhoneSize = useIsPhoneSize();
  const { useCallFeedV2DetailedMode } = useCallFeedFeatureFlags();
  const [{ settings }] = useOrgSettings();
  const rationIdToDmRatio = useRationIdToDmRatio(rations);

  const {
    'feeding.dmiPerHeadIncrement': settingCallFeedDmiPerHeadIncrement,
    'feeding.afPerHeadIncrement': settingCallFeedAfPerHeadIncrement,
    'feeding.afIncrement': settingCallFeedAfIncrement,
    'feeding.primaryCallMethod': settingCallFeedPrimaryCallMethod,
  } = settings;

  // We do not use the return value of useFieldArray() beause it doesn't update when you change something inside the array
  // The value returned by useWatch() does update
  // But if you don't call useFieldArray() then isDirty doesn't get set even if you pass {shouldDirty: true} to setValue()
  // This is probably a bug in react-hook-forms but the documentation is so vague that it isn't clear how it should work
  useFieldArray({ control, name: `pens.${penIndex}.drops` });
  const [selectedAllRationId, callBy, maybeDrops] = useWatch({
    control,
    name: [`pens.${penIndex}.selectedAllRationId`, `callBy`, `pens.${penIndex}.drops`],
  });
  const drops = maybeDrops ?? Empty;

  const penHasMultipleRations = useMemo(() => new Set(drops.map((drop: any) => drop.rationId)).size > 1, [drops]);

  const disablePerHeadNumberFields = useMemo(
    () => drops.length === 0 || !!drops?.find((drop) => !drop.rationId) || penHasMultipleRations,
    [drops, penHasMultipleRations]
  );

  const disableTotalCallNumberField = useMemo(
    () =>
      drops.length === 0 ||
      !!drops?.find((drop) => drop?.load?.stage && drop?.load?.stage !== LoadStage.NotYetStarted) ||
      !!drops?.find((drop) => !drop.rationId) ||
      penHasMultipleRations ||
      !selectedAllRationId,
    [drops, penHasMultipleRations, selectedAllRationId]
  );

  const updateTotalAndDmi = useCallback(
    (props?: { skipAsFedPerHead?: boolean; skipTotalWeight?: boolean; skipDmiPerHead?: boolean }) => {
      const drops = getValues().pens[penIndex].drops as PenDrop[];
      const dmiPerHead = getDmiPerHeadByDrops(drops, rationIngredientsMap, true);
      const totalCalledLbs = drops.reduce((acc: number, drop: PenDrop) => acc + (drop.calledLbs ?? 0), 0);

      if (!props?.skipAsFedPerHead)
        setValue(`pens.${penIndex}.asFedPerHead`, twoDecimals(totalCalledLbs / pen.numHeads), { shouldDirty: false });
      if (!props?.skipTotalWeight)
        setValue(`pens.${penIndex}.totalWeight`, twoDecimals(totalCalledLbs), { shouldDirty: false });
      if (!props?.skipDmiPerHead)
        setValue(`pens.${penIndex}.dmiPerHead`, twoDecimals(dmiPerHead), { shouldDirty: false });
    },
    [getValues, pen.numHeads, penIndex, rationIngredientsMap, setValue]
  );

  const updateDropsByAmount = useCallback(
    (requestedTotal = 0) => {
      // We will distribute the changes across the drops that have not been started
      const allDrops = (getValues().pens[penIndex].drops as PenDrop[]).map((drop, index) => ({ drop, index }));
      const unstartedDrops = allDrops.filter(
        ({ drop }) => (drop.load?.stage ?? LoadStage.NotYetStarted) === LoadStage.NotYetStarted
      );
      const drops = unstartedDrops.length === 0 ? allDrops : unstartedDrops;
      const previousTotalAll = sumBy(allDrops, ({ drop }) => drop.calledLbs ?? 0);
      const previousTotal = sumBy(drops, ({ drop }) => drop.calledLbs ?? 0);
      const newTotal = requestedTotal - previousTotalAll + previousTotal;

      // Each drop has a lastOver0CalledLbs value that is set when the calledLbs is greater than 0
      // This is used to preserve the original calledLbs value when the total is 0
      // if all lastOver0CalledLbs are 0, we need to calculate the ratio based on the current total
      // Typical scenario is when the total is 0 and user have drops with 0 calledLbs initally
      const lastOver0Total = sumBy(drops, ({ drop }) => drop.lastOver0CalledLbs ?? 0);

      // we will distribute the changes across each drop according to each drop's proportion to the total
      // unless the total was zero, in which case we will distribute the changes evenly
      const divisor = previousTotal || lastOver0Total;
      drops.forEach(({ drop, index }) => {
        const calledLbs = (previousTotal ? drop.calledLbs : drop.lastOver0CalledLbs) ?? 0;
        const proportion = divisor
          ? // there was a previous total, so the proportion is based on the previous value's proportion of the previous total
            calledLbs / divisor
          : // the previous total was zero, so the proportion is just the even distribution
            1 / drops.length;
        setValue(`pens.${penIndex}.drops.${index}.calledLbs`, Math.max(Math.round(newTotal * proportion), 0), {
          shouldDirty: true,
        });
      });
    },
    [getValues, penIndex, setValue]
  );

  const updateDropsByAsFedPerHead = useCallback(
    (asFedPerHead = 0) => {
      const newTotal = asFedPerHead * pen.numHeads;
      updateDropsByAmount(newTotal);
    },
    [pen.numHeads, updateDropsByAmount]
  );

  const updateDropsByDmi = useCallback(
    (value: number) => {
      const drops = getValues().pens[penIndex].drops as Drop[];
      const drop = drops[0];
      const dmRatio = rationIdToDmRatio(drop.rationId);

      const newTotal = getCalledLbsFromDmiPerHead(value, dmRatio, pen.numHeads);
      updateDropsByAmount(newTotal);
    },
    [getValues, pen.numHeads, penIndex, updateDropsByAmount, rationIdToDmRatio]
  );

  const onTotalWeightChange = useCallback(
    (value: number) => {
      updateDropsByAmount(value);
      updateTotalAndDmi({ skipTotalWeight: true });
    },
    [updateDropsByAmount, updateTotalAndDmi]
  );

  const onDmiPerHeadChange = useCallback(
    (value: number) => {
      updateDropsByDmi(value);
      updateTotalAndDmi({ skipDmiPerHead: true });
    },
    [updateDropsByDmi, updateTotalAndDmi]
  );

  const onAsPerHeadChange = useCallback(
    (value: number) => {
      updateDropsByAsFedPerHead(value);
      updateTotalAndDmi({ skipAsFedPerHead: true });
    },
    [updateDropsByAsFedPerHead, updateTotalAndDmi]
  );

  const updateCallBy = useCallback(
    (callBy: CallBy) => {
      setValue(`callBy`, callBy, { shouldDirty: false });
      callFeedKvStore.set({ callBy });
    },
    [setValue]
  );

  const onSelectedAllRationIdChange = useCallback(
    (rationId: number) => {
      const drops = getValues().pens[penIndex].drops as Drop[];
      drops.forEach((drop, index) => {
        setValue(`pens.${penIndex}.drops.${index}.rationId`, rationId, { shouldDirty: true });
        if (rationId) {
          const rationRevision = rations.find((r) => r.id === rationId)!.currentRevision;
          setValue(`pens.${penIndex}.drops.${index}.rationRevision`, rationRevision, { shouldDirty: true });
        }
      });
      updateTotalAndDmi();
    },
    [getValues, penIndex, rations, setValue, updateTotalAndDmi]
  );

  const updateSelectedAllRation = useCallback(() => {
    const drops = getValues().pens[penIndex].drops as Drop[];
    const hasSameRation = new Set(drops.map((drop) => drop.rationId)).size === 1;
    setValue(`pens.${penIndex}.selectedAllRationId`, hasSameRation ? drops[0]?.rationId : null, {
      shouldDirty: false,
    });

    updateTotalAndDmi();
  }, [getValues, penIndex, setValue, updateTotalAndDmi]);

  const addDrop = useCallback(() => {
    const pen = getValues().pens[penIndex];
    const drops = pen.drops as PenDrop[];
    const { id, ...lastDrop } = drops[drops.length - 1] || {
      rationId: rations[0].id,
      rationRevision: rations[0].currentRevision,
      numHeads: pen.numHeads,
    };

    setValue(
      `pens.${penIndex}.drops`,
      [
        ...drops,
        {
          ...lastDrop,
          _uuid: uuid(),
          date: selectedDate,
          calledLbs: 0,
        },
      ],
      { shouldDirty: true }
    );

    updateSelectedAllRation();
  }, [getValues, penIndex, rations, selectedDate, setValue, updateSelectedAllRation]);

  const deleteDrop = useCallback(
    (dropIndex: number) =>
      showConfirmationModal({
        title: 'Delete Drop',
        message: 'Are you sure you want to delete this drop?',
        confirmButton: 'Delete',
        onConfirm: () => {
          const drops = getValues().pens[penIndex].drops as PenDrop[];
          drops.splice(dropIndex, 1);
          setValue(`pens.${penIndex}.drops`, drops, { shouldDirty: true });

          updateSelectedAllRation();
        },
      }),
    [getValues, penIndex, setValue, updateSelectedAllRation]
  );

  const onDropCalledLbsChange = (dropIndex: number, value: number) => {
    if (value > 0) {
      setValue(`pens.${penIndex}.drops.${dropIndex}.lastOver0CalledLbs`, value, { shouldDirty: true });
    }
    updateTotalAndDmi();
  };

  const isCallFeedV2ByDistribution =
    useCallFeedV2DetailedMode && settingCallFeedPrimaryCallMethod.method === PlanCallMethod.ByDistribution;

  return (
    <PenDropsListContainer data-testid={`call-feed-pen-${inDialog ? 'detailed' : 'simple'}`}>
      {!inDialog && (
        <PenDropsListHeaderLegacy
          pen={pen}
          pens={pens}
          penIndex={penIndex}
          rations={rations}
          rationIngredientsMap={rationIngredientsMap}
          selectedDate={selectedDate}
          showDetailModal={showDetailModal}
          formMethods={formMethods}
          control={control}
          isTodaySelected={isTodaySelected}
          formRef={formRef}
        />
      )}

      {!isCallFeedV2ByDistribution && (
        <FieldsStack direction={isPhoneSize ? 'column' : 'row'} spacing={isPhoneSize ? 1 : 2}>
          {isPhoneSize && (
            <PenDropsListHeaderBunkScoreText data-testid="call-feed-pen-bunkScore-text">
              <Box sx={{ width: isPhoneSize ? '100%' : 160 }}>
                <BunkScoreSelectField control={control} penIndex={penIndex} isTodaySelected={isTodaySelected} />
              </Box>
            </PenDropsListHeaderBunkScoreText>
          )}
          <Box>
            <PenDropEditorContainer>
              {!isPhoneSize && (
                <PenDropEditorContainerHeader>
                  <PenDropEditorContainerDropText>Detail Call</PenDropEditorContainerDropText>
                </PenDropEditorContainerHeader>
              )}
              <Stack spacing={1} direction={isPhoneSize ? 'row' : 'column'}>
                <StackFlexOne direction={'row'} spacing={1}>
                  <Button
                    {...(callBy !== CallBy.DMI_PER_HEAD ? { color: 'secondary' } : {})}
                    variant="outlined"
                    sx={{ minWidth: 0, paddingX: 0, flex: 1 }}
                    data-testid={`call-feed-pen-${inDialog ? 'detailed' : 'simple'}-callBy-dmi-button`}
                    onClick={() => updateCallBy(CallBy.DMI_PER_HEAD)}
                  >
                    DMI / HD
                  </Button>
                  <Button
                    {...(callBy !== CallBy.AS_FED_PER_HEAD ? { color: 'secondary' } : {})}
                    variant="outlined"
                    sx={{ minWidth: 0, paddingX: 0, flex: 1 }}
                    data-testid={`call-feed-pen-${inDialog ? 'detailed' : 'simple'}-callBy-asFed-button`}
                    onClick={() => updateCallBy(CallBy.AS_FED_PER_HEAD)}
                  >
                    AF / HD
                  </Button>
                </StackFlexOne>
                <BoxFlexOne>
                  {callBy === CallBy.DMI_PER_HEAD && (
                    <ControlledNumberFieldWithIncrements
                      control={control}
                      name={`pens.${penIndex}.dmiPerHead`}
                      data-testid={`call-feed-pen-${inDialog ? 'detailed' : 'simple'}-callBy-dmiPerHead`}
                      decimalPlaces={2}
                      disabled={disablePerHeadNumberFields}
                      increment={settingCallFeedDmiPerHeadIncrement}
                      onChange={onDmiPerHeadChange}
                    />
                  )}
                  {callBy === CallBy.AS_FED_PER_HEAD && (
                    <ControlledNumberFieldWithIncrements
                      control={control}
                      name={`pens.${penIndex}.asFedPerHead`}
                      data-testid={`call-feed-pen-${inDialog ? 'detailed' : 'simple'}-callBy-asFedPerHead`}
                      decimalPlaces={2}
                      disabled={disablePerHeadNumberFields}
                      increment={settingCallFeedAfPerHeadIncrement}
                      onChange={onAsPerHeadChange}
                    />
                  )}
                </BoxFlexOne>
              </Stack>
            </PenDropEditorContainer>
          </Box>

          <TotalCallAsFedBox>
            <PenDropEditorContainer>
              {!isPhoneSize && (
                <PenDropEditorContainerHeader>
                  <PenDropEditorContainerDropText>Total Call AF</PenDropEditorContainerDropText>
                </PenDropEditorContainerHeader>
              )}
              <Stack spacing={1} direction={isPhoneSize ? 'row' : 'column'}>
                <BoxFlexOne>
                  <ControlledSelectField
                    control={control}
                    name={`pens.${penIndex}.selectedAllRationId`}
                    disabled={drops.length === 0 || drops.some(shouldDisableDrop)}
                    data-testid={`call-feed-pen-${inDialog ? 'detailed' : 'simple'}-all-ration-select-field`}
                    onChange={(e) => onSelectedAllRationIdChange(Number(e.target.value))}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {rations.map((ration) => (
                      <MenuItem key={ration.id} value={ration.id}>
                        {ration.name}
                      </MenuItem>
                    ))}
                  </ControlledSelectField>
                </BoxFlexOne>
                <BoxFlexOne>
                  <ControlledNumberFieldWithIncrements
                    control={control}
                    name={`pens.${penIndex}.totalWeight`}
                    disabled={disableTotalCallNumberField}
                    increment={settingCallFeedAfIncrement}
                    onChange={onTotalWeightChange}
                  />
                </BoxFlexOne>
              </Stack>
            </PenDropEditorContainer>
          </TotalCallAsFedBox>

          <PenDropsListDropsContainer>
            <PenDropsListDropsScroller>
              <PenDropsListDropsStack
                spacing={isPhoneSize ? 1 : 2}
                direction={isPhoneSize ? 'column' : 'row'}
                count={drops.length}
              >
                {/* deleting a drop did not actually delete when using `drops` var. */}
                {drops.map((drop, index) => (
                  <PenDropEditor
                    key={drop.id ?? drop._uuid}
                    dropId={drop.id ?? drop._uuid ?? `drop-${index}`}
                    dropIndex={index}
                    penIndex={penIndex}
                    rations={rations}
                    inDialog={inDialog}
                    control={control}
                    disabled={shouldDisableDrop(drop)}
                    onDeleteDrop={deleteDrop}
                    onCalledLbsChange={onDropCalledLbsChange}
                    onRationChange={(e: any) => {
                      const rationId: number | undefined = Number(e.target.value);
                      // We need to update drop's revision accordingly
                      const rationRevision = rations.find((r) => r.id === rationId)?.currentRevision;
                      setValue(`pens.${penIndex}.drops.${index}.rationRevision`, rationRevision, {
                        shouldDirty: false,
                      });
                      updateSelectedAllRation();
                    }}
                  />
                ))}

                <AddDropButtonWrap>
                  <AddDropButton
                    data-testid={`call-feed-pen-${inDialog ? 'detailed' : 'simple'}-add-drop-button`}
                    onClick={addDrop}
                  >
                    <AddIcon />
                  </AddDropButton>
                </AddDropButtonWrap>
              </PenDropsListDropsStack>
            </PenDropsListDropsScroller>
          </PenDropsListDropsContainer>
        </FieldsStack>
      )}
    </PenDropsListContainer>
  );
};

type Rations = Parameters<typeof getRationCurrentRevisionToDryMatterRatio>[0];

function useRationIdToDmRatio(rations: Rations) {
  const rationIdToDmRatio = useMemo(() => {
    const getDmRatio = getRationCurrentRevisionToDryMatterRatio(rations);
    // we return a version that will handle an undefined or 0 value sanely
    // this way if the user selects "none" this remains callable without throwing and returns a value that remains consistent
    return (rationId: number | undefined) => (!rationId ? 1.0 : getDmRatio(rationId));
  }, [rations]);
  return rationIdToDmRatio;
}

function shouldDisableDrop(drop: { load?: null | { stage: LoadStage } }) {
  return drop.load?.stage != null && drop.load?.stage != LoadStage.NotYetStarted;
}

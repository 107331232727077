import { Box, Dialog, Stack } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import {
  CallFeedPenReadonlyData,
  CallFeedPensMutableData,
  PlanAndObservations,
  useCallFeedData,
} from './hooks/useCallFeedData';
import { RationToDryMatterRatio } from '@/common/models/ration';
import { CallFeedPenInput, PlanCallMethod, PlanCallUnit } from '@/web-types';
import { RationOption } from './hooks/useRations';
import { isEqual } from 'lodash';
import { CallFeedPlan } from './CallFeedPlan';
import { Day } from './callPlan';
import { DuplicateDropsEmptyView } from '../CallFeedTab/DuplicateDropsEmptyView';
import { addDays } from '@/common/utils/date';
import { TodayTomorrowStack } from './TodayTomorrowStack';
import React from 'react';
import { DevToolDateOverride } from '../CallFeedTab';
import { useIsSuperAdmin } from '../Admin/useIsSuperAdmin';
import { OrgSettings } from '@/common/types/settings';
import { localdate } from '@/common/models/types';
import { getDataToSave } from './hooks/useCallFeedData/save';
import { useWorking } from './hooks/useWorking';
import { CallFeedSimpleView } from './CallFeedSimpleView';
import { DbPen, LegacyMode, SavePenDropsAndObservations } from './CallFeedTab';
import { SimpleViewHeaderContainer } from './SimpleViewHeaderContainer';
import { useUnsavedWarningDialog } from '../UnsavedWarningDialog/useUnsavedWarningDialog';

export type CallFeedPen = {
  penId: number;
  workingData: PlanAndObservations;
  penData: CallFeedPenReadonlyData;
  setWorking: (modify: (prevPen: PlanAndObservations) => PlanAndObservations) => void;
  showDetailedView: () => void;
};

// This converts from raw data into higher level data structures (and back again during save)
// and sets up a "working" state.
// and then renders ths simple and detailed views
export type CallFeedTabViewProps = {
  pens: DbPen[];
  save: SavePenDropsAndObservations;
  saving: boolean;
  today: localdate;
  callUnit: PlanCallUnit;
  callMethod: PlanCallMethod;
  rations: readonly RationOption[];
  rationIdToDmRatio: RationToDryMatterRatio;
  day: Day;
  setDay: (day: Day) => void;
  didDuplicate: () => void;
  settings: OrgSettings;
  legacyMode?: LegacyMode;
};
export const CallFeedTabView: React.FC<CallFeedTabViewProps> = ({
  pens: dbPens,
  save,
  saving,
  today,
  callUnit,
  callMethod,
  rations,
  rationIdToDmRatio,
  day,
  setDay,
  didDuplicate,
  settings,
  legacyMode,
}) => {
  // TODO: remove this temporary kludge when we are capable of by-pen duplication
  const tomorrow = addDays(today, 1);
  const duplicationHasOccurred = dbPens?.some((pen) => pen.drops?.some((drop) => drop.date == tomorrow)) ?? false;

  const processedPenData = useCallFeedData(dbPens, today, callUnit, callMethod, duplicationHasOccurred);

  const readonlyPenData = processedPenData.readonly;
  const saveProcessed = useCallback(
    async (working: CallFeedPensMutableData, previous: CallFeedPensMutableData | undefined) => {
      const dirtyEntries = [...working.entries()]
        .filter(([penId, penData]) => {
          const prev = previous?.get(penId);
          return !prev || !isEqual(penData, prev); // TODO: check saved
        })
        .map(([penId, penData]) => ({ penId, entry: penData }));
      if (dirtyEntries.length === 0) {
        return 'success';
      }
      console.debug(`saving pen ids ${dirtyEntries.map((entry) => entry.penId)}`);

      const pens: CallFeedPenInput[] = [];

      for (const { penId, entry } of dirtyEntries) {
        const _dropsMap = readonlyPenData.get(penId)?.dropsMap;
        if (!_dropsMap) {
          console.error(`Tried to save pen ${penId} but its dropsMap was not present`);
          continue;
        }
        const dropsMap = _dropsMap!;
        const { drops, bunkScore, confirmDropIdsToDelete, planJson } = getDataToSave(
          entry,
          today,
          penId,
          dropsMap.today,
          dropsMap.tomorrow,
          dropsMap.duplicationHasOccurred,
          rationIdToDmRatio
        );
        pens.push({ penId: penId, drops, bunkScore, confirmDropIdsToDelete, planJson });
      }

      const result = await save({ pens }, { additionalTypenames: ['Drop'] });
      if (result.error) {
        return { error: result.error };
      }
      return 'success';
    },
    [readonlyPenData, save, rationIdToDmRatio, today]
  );

  const [working, { setWorking, flags, saveWorking, resetWorking }] = useWorking(
    processedPenData.mutable,
    saveProcessed
  );

  const unsavedDialog = useUnsavedWarningDialog(flags.dirty, saveWorking, saving);

  const increments = useMemo(
    () => ({
      dmiPerHead: settings['feeding.dmiPerHeadIncrement'],
      afPerHead: settings['feeding.afPerHeadIncrement'],
      af: settings['feeding.afIncrement'],
    }),
    [settings]
  );

  const currentPenState = useState<number | undefined>(undefined);
  const [currentPenId, setCurrentPenId] = legacyMode ? [legacyMode.penId, legacyMode.onChangePen] : currentPenState;
  const hideDetailView = useCallback(() => setCurrentPenId(undefined), [setCurrentPenId]);

  const penList = useMemo(
    () => dbPens.map((pen) => ({ id: pen.id, name: pen.name, numHeads: pen.numHeads ?? 0 })),
    [dbPens]
  );

  // Here is where we need to do (and eventually individually memoize) the per-pen calculations
  const pens: CallFeedPen[] = useMemo(
    () =>
      [...working.entries()].map(([penId, workingData]) => ({
        penId,
        workingData,
        penData: readonlyPenData.get(penId)!,
        setWorking: collectionSetterToItemSetter(setWorking, penId),
        showDetailedView: () => setCurrentPenId(penId),
      })),
    [working, readonlyPenData, setWorking, setCurrentPenId]
  );

  const currentPen = currentPenId ? pens.find((pen) => pen.penId === currentPenId) : undefined;

  const isSuperAdmin = useIsSuperAdmin();

  const feedingMethod = settings['feeding.feedingMethod'];

  const showingDetailedView = currentPen != null;

  if (!legacyMode) {
    const canDuplicate =
      pens.length > 0 &&
      // TODO: (Warren) reconsider using "dropsMap" for this. We should really pass down "can duplicate" from further up. Currently dropsMap contains every drop but that's not its purpose, so in the future this can get confusing. We should calculate this value earlier in the processing at a point where we are still dealing with *all* the drops
      pens.every((pen) => pen.penData.dropsMap.today.length === 0) &&
      pens.some((pen) => pen.penData.yesterdayDropsMap.length > 0);
    const mustDuplicateForToday = day === 'today' && canDuplicate;
    const mustDuplicateTomorrow = day === 'tomorrow' && !duplicationHasOccurred;
    const yesterday = addDays(today, -1);

    if (mustDuplicateForToday) {
      return (
        <>
          <DuplicateDropsEmptyView
            isFullScreen={true}
            isTodaySelected={true}
            fromDate={yesterday}
            toDate={today}
            onSuccess={didDuplicate}
          />
          {isSuperAdmin && <DevToolDateOverride />}
          {unsavedDialog}
        </>
      );
    }
    if (mustDuplicateTomorrow && !showingDetailedView) {
      return (
        <Stack direction="column">
          <SimpleViewHeaderContainer>
            <TodayTomorrowStack data-testid="call-feed-simple-day-select" value={day} onChange={setDay} />
          </SimpleViewHeaderContainer>
          <DuplicateDropsEmptyView
            isFullScreen={true}
            isTodaySelected={false}
            fromDate={today}
            toDate={tomorrow}
            onSuccess={didDuplicate}
          />
          {isSuperAdmin && <DevToolDateOverride />}
          {unsavedDialog}
        </Stack>
      );
    }
  }

  return (
    <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {!legacyMode ? (
        // Simple View is not rendered in Legacy Mode
        <>
          {isSuperAdmin && <DevToolDateOverride />}
          <CallFeedSimpleView
            day={day}
            setDay={setDay}
            dirty={flags.dirty}
            clearChanges={resetWorking}
            saveWorking={saveWorking}
            pens={pens}
            rationIdToDmRatio={rationIdToDmRatio}
            rations={rations}
            feedingMethod={feedingMethod}
            increments={increments}
          />
        </>
      ) : null}
      <Dialog fullScreen open={showingDetailedView} fullWidth>
        {currentPen != null ? (
          <CallFeedPlan
            key={currentPen.penId}
            penId={currentPen.penId}
            penData={currentPen.penData}
            penList={penList}
            workingData={currentPen.workingData}
            rations={rations}
            haveTomorrow={duplicationHasOccurred}
            saving={saving}
            setWorkingData={currentPen.setWorking}
            saveWorking={saveWorking}
            rationIdToDmRatio={rationIdToDmRatio}
            didDuplicate={didDuplicate}
            feedingMethod={feedingMethod}
            increments={increments}
            onExit={hideDetailView}
            onChangePen={setCurrentPenId}
            day={day}
            setDay={setDay}
            dirty={flags.dirty}
          />
        ) : null}
      </Dialog>
      {unsavedDialog}
    </Box>
  );
};

/**
 * Given a setter function for a ReadonlyMap in the form `set((oldMap) => newMap)`
 * creates a setter function for a single item in the form `set((oldItem) => newItem)`
 **/
const collectionSetterToItemSetter =
  <TKey, TItem>(mapSet: (modify: (prev: ReadonlyMap<TKey, TItem>) => ReadonlyMap<TKey, TItem>) => void, key: TKey) =>
  (modify: (prevItem: TItem) => TItem) =>
    // This works by returning a new "all pens" collection but with the selected pen's data modified
    mapSet((prevMap) => replace(prevMap, key, (prevPen) => modify(prevPen)));

/** Return a map with an item replaced */
const replace = <K, V>(map: ReadonlyMap<K, V>, key: K, fn: (prev: V) => V): ReadonlyMap<K, V> => {
  const prevItem = map.get(key);
  const nextMap = new Map(map);
  if (prevItem) {
    nextMap.set(key, fn(prevItem));
  } else {
    console.error(`Tried to replace ${key} but it was not present`);
  }
  return nextMap;
};

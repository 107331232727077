import { RationToDryMatterRatio } from '@/common/models/ration';
import { sum } from 'lodash';
import { normalizeDistribution, redistributeAroundForced } from './functions';
import { planUnitConversionFactor, planUnitConvert } from '@/common/callFeed/planCallUnit';
import { CallFeedDropIdAndActual, rationOrLast } from '.';
import { PlanCallUnit } from '@/web-types';

type CalCallsForByDistributionPlanArgs = {
  planDistribution: readonly number[];
  planPenAmount: number;
  planUnit: PlanCallUnit;
  planRationIds: readonly number[];
  currentHead: number;
  rationIdToDmRatio: RationToDryMatterRatio;
  drops: readonly CallFeedDropIdAndActual[];
  adjustLastForActualFed: boolean;
};

/**
 * Calculate the recurring calls given a per-head plan
 * @param planDistribution The distribution configured by the plan
 * @param planPenAmount The amount for the whole pen (whether dmi per head or as fed per head)
 * @param planUnit The unit of the amount for the pen
 * @param planRationIds the ration ids for each drop from the plan
 * @param currentHead the current head count of the pen
 * @param rationIdToDmRatio converter from ration to dm ratio
 * @param drops the drop for the day with actuals
 * @returns the calls for each drop
 */
export function calcCallsForPerDistributionPlan({
  planDistribution,
  planPenAmount,
  planUnit,
  planRationIds,
  currentHead,
  rationIdToDmRatio,
  drops,
  adjustLastForActualFed,
}: CalCallsForByDistributionPlanArgs) {
  const dropMap = drops
    .filter((d) => !d.oneTime)
    .map((d) => ({
      dropId: d.dropId,
      actual: d.actual
        ? {
            ...d.actual,
            called: planUnitConvert(
              d.actual.afLbsCalled,
              PlanCallUnit.AsFedLbs,
              planUnit,
              d.actual.head,
              rationIdToDmRatio(d.actual.rationId)
            ),
            afLbsFed: d.actual.afLbsFed,
          }
        : undefined,
    }));
  const recurring = calcRecurringForByDistribution({
    planDistribution,
    planPenAmount,
    planRationIds,
    dropMap,
    adjustLastForActualFed,
  });
  return recurring.map(({ drop, amountInPlanUnits, varianceCompensation, rationId }) => {
    const head = drop?.actual?.head ?? currentHead;
    // this is the factor to convert between plan units and as-fed total
    const conversionFactor = planUnitConversionFactor(
      planUnit,
      PlanCallUnit.AsFedLbs,
      head,
      rationIdToDmRatio(rationId)
    );
    return {
      dropId: drop?.dropId,
      rationId,
      head,
      amount: {
        afLbs: (amountInPlanUnits + varianceCompensation) * conversionFactor,
        // remember the conversion so it can be undone. See `type CallAmount` for detailed explanation
        toPlan: { m: conversionFactor, b: 0, c: 0 + varianceCompensation },
      },
    };
  });
}

type Actual = {
  head: number;
  rationId: number;
  afLbsCalled: number;
  called: number;
  afLbsFed: number;
};

export type CalcRecurringForByDistributionArgs = {
  planDistribution: readonly number[];
  planPenAmount: number;
  planRationIds: readonly number[];
  dropMap: { dropId: number; actual: Actual | undefined }[];
  adjustLastForActualFed: boolean;
};

export function calcRecurringForByDistribution({
  planDistribution,
  planPenAmount,
  planRationIds,
  dropMap,
  adjustLastForActualFed,
}: CalcRecurringForByDistributionArgs) {
  // the same algorithm works regardless of the plan unit
  // get the amount of each drop according to the plan in plan units (dmi per head for dmi per head plan etc.)
  // the count is how many drops are configured by the plan, plus any actuals that do not match a plan drop
  const unmatchedActuals = dropMap.slice(planDistribution.length).filter((a) => a.actual);
  const length = planDistribution.length + unmatchedActuals.length;
  // here is the distribution of the amount for each drop according to the plan
  const distributed = Array.from({ length }).map((_, i) => (planDistribution[i] ?? 0) * planPenAmount);
  // correlate each to a day drop
  const dayDrops = distributed.map((_, i) => {
    const dayDrop = i < planDistribution.length ? dropMap[i] : unmatchedActuals[i - planDistribution.length];
    return dayDrop;
  });
  const forced = dayDrops.map((dd) => (dd?.actual?.called ? dd.actual.called : undefined));

  const last = distributed.length - 1;
  // const variance = adjustLastForActualFed ? 0 : 0; // TODO: calculate variance (total fed )
  const variance = 0;

  // redistribute the called amounts proportionally around the forced amounts
  // this may also add a drop if all drops are forced to a total amount less than the plan amount
  const redistributed = redistributeAroundForced(distributed, forced);

  return redistributed
    .filter((amountInPlanUnits) => amountInPlanUnits >= 0)
    .map((amountInPlanUnits, i) => {
      const drop = dayDrops[i];
      return {
        drop: drop,
        amountInPlanUnits,
        varianceCompensation: i === last ? -variance : 0,
        rationId: drop?.actual?.rationId ?? rationOrLast(planRationIds, i),
      };
    });
}

/** When a user specifies the amount of a particular drop in a distribution-based plan
 *  this plan solves what should be the total amount (in plan units) and the new distribution to achieve that amount
 *  taking into account the drops which are forced by actuals
 * @param planAmount the daily amount for the pen in plan units
 * @param planDistribution the distribution for the pen in plan units
 * @param dropIndex the index of the drop to be changed
 * @param dropAmount the desired resulting amount (in plan units) for this drop
 * @param actualAmounts the amounts of the actuals
 */
export function solveAmountAndDistributionForSpecifiedDropAmount(
  planAmount: number,
  planDistribution: readonly number[],
  dropIndex: number,
  dropAmount: number,
  actualMap: readonly (number | undefined)[]
) {
  // TODO: This is incorrect when there are actuals that do not match drops (happens if you reduce distribution after feeding last drop)
  //       note: that makes the code here share a lot with the code in calcCallsForPerDistributionPlan which implies there is a simplficiation we can make
  // the amount distributed across the distribution from the plan
  const distributed = planDistribution.map((d, i) => d * planAmount);
  // the amounts forced by actuals
  const forced = planDistribution.map((_, i) => {
    return actualMap[i];
  });
  // the amounts redistributed around the forced amounts
  const redistributed = redistributeAroundForced(distributed, forced);
  // the amounts but with the specified drop changed to the specified amount
  const desired = redistributed.map((d, i) => {
    return i === dropIndex ? dropAmount : d;
  });
  // the new daily total of the plan
  const newPlanAmount = sum(desired);
  // if the index being edited is within the distribution, return the distribution that results in the desired amounts
  const newDistribution = dropIndex < planDistribution.length ? normalizeDistribution(desired) : planDistribution;
  return { distribution: newDistribution, amount: newPlanAmount };
}

import { Stack } from '@mui/material';
import { NumberInput, NumberInputProps } from './NumberInput';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useCallback } from 'react';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  background: '#fff',
  border: `1px solid ${theme.palette.grey[400]}`,
}));
StyledIconButton.defaultProps = {
  size: 'small',
};

const LeftIconButton = styled(StyledIconButton)(({ theme }) => ({
  borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
  borderRight: 0,
}));

const RightIconButton = styled(StyledIconButton)(({ theme }) => ({
  borderRadius: ` 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
  borderLeft: 0,
}));

export type IncrementalNumberInputProps = NumberInputProps & { increment?: number; allowNegative?: boolean };

export const IncrementalNumberInput: React.FC<IncrementalNumberInputProps> = ({
  increment = 1,
  allowNegative = false,
  'data-testid': dataTestId,
  ...numberInputProps
}) => {
  const { disabled, onChange, params, value } = numberInputProps;
  const onSubtractClick = useCallback(
    () => onChange(allowNegative ? value - increment : Math.max(value - increment, 0), params),
    [onChange, allowNegative, value, increment, params]
  );
  const dtid = dataTestId ?? numberInputProps?.inputProps?.['data-testid'];
  const onAddClick = useCallback(() => onChange(value + increment, params), [value, increment, onChange, params]);
  return (
    <Stack direction="row" spacing={0} width="100%">
      <LeftIconButton
        disabled={disabled || (!allowNegative && value <= 0)}
        onClick={onSubtractClick}
        data-testid={dtid ? `${dtid}-subtract-button` : undefined}
      >
        <RemoveIcon />
      </LeftIconButton>
      <NumberInput
        data-testid={dtid}
        {...numberInputProps}
        sx={{ '.MuiInputBase-formControl': { borderRadius: 0 }, input: { textAlign: 'center' } }}
      />
      <RightIconButton disabled={disabled} onClick={onAddClick} data-testid={dtid ? `${dtid}-add-button` : undefined}>
        <AddIcon />
      </RightIconButton>{' '}
    </Stack>
  );
};

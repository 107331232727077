import { RationToDryMatterRatio } from '@/common/models/ration';
import { CallFeedDropIdAndActual, CallFeedDropsMap, PenCallPlan } from '../../callPlan';
import { localdate } from '@/common/models/types';
import { addDays } from '@/common/utils/date';
import { PenDayDropsInput, BunkScoreValue, DropInput, PlanCallMethod, PlanCallUnit } from '@/web-types';
import {
  byDistributionPlanToDb,
  byDropPlanToDb,
  DbOneTimeDrop,
  DbRecurringDrop,
  PlanDbDrops,
} from '../../callPlan/toFromDb';
import assertNever from 'assert-never';
import { PlanAndObservations } from './types';
import { isPresent } from 'ts-is-present';

type SaveData = {
  drops: PenDayDropsInput[];
  bunkScore: BunkScoreValue | null;
  confirmDropIdsToDelete: number[];
  planJson: {
    plan: PenCallPlan;
    todayDropsMap: readonly CallFeedDropIdAndActual[];
    tomorrowDropsMap: readonly CallFeedDropIdAndActual[];
  };
};

/** Calculates the data to save to the backend for the working plan */
export const getDataToSave = (
  planAndObservations: PlanAndObservations,
  today: localdate,
  penId: number,
  todayDropsMap: readonly CallFeedDropIdAndActual[],
  tomorrowDropsMap: readonly CallFeedDropIdAndActual[],
  duplicationHasOccurred: boolean,
  rationIdToDmRatio: RationToDryMatterRatio
): SaveData => {
  const dropsMap = { today: todayDropsMap, tomorrow: tomorrowDropsMap, duplicationHasOccurred };
  const { plan, observationsToday } = planAndObservations;
  const planDrops = planToPlanDrops(plan, dropsMap, rationIdToDmRatio);
  const recurringToday = planDrops.today.recurring.map(recurringToDropInput(planDrops.callUnit));
  const recurringTomorrow = planDrops.tomorrow.map(recurringToDropInput(planDrops.callUnit));
  const oneTime = planDrops.today.oneTime.map(oneTimeToDropInput);
  const todayDrops: PenDayDropsInput = {
    penId,
    date: today,
    drops: [...recurringToday, ...oneTime],
  };
  const tomorrowDrops: PenDayDropsInput = {
    penId,
    date: addDays(today, 1),
    drops: recurringTomorrow,
  };
  const dropIdsExisting = [...todayDropsMap, ...tomorrowDropsMap].map((d) => d.dropId);
  const dropIdsNotDeleted = new Set([...todayDrops.drops, ...tomorrowDrops.drops].map((d) => d.id).filter(isPresent));
  const dropIdsToDelete = dropIdsExisting.filter((id) => !dropIdsNotDeleted.has(id));
  console.debug({ dropIdsExisting, dropIdsNotDeleted, dropIdsToDelete });
  return {
    drops: [todayDrops, tomorrowDrops],
    bunkScore: observationsToday.bunkScore ?? null,
    confirmDropIdsToDelete: dropIdsToDelete,
    planJson: { plan, todayDropsMap, tomorrowDropsMap },
  };
};

/** Given a recurring drop, convert it into a DropInput to send to the saveCallFeed mutation */
const recurringToDropInput = (callUnit: PlanCallUnit) => (d: DbRecurringDrop) => {
  const dropInput: DropInput = {
    id: d.id, // not used for recurring drops
    rationId: d.rationId,
    calledLbs: undefined, // not used for recurring drops
    planCallAmount: d.amount,
    planCallUnit: callUnit,
    planDayOverride: d.override,
  };
  return dropInput;
};

/**
 * Converts a feed plan to the database format
 * @param plan - Feed plan to convert
 * @param dropsMap - Map of feed drops
 * @param rationIdToDmRatio - Mapping of ration IDs to dry matter ratios
 * @returns Plan in database format
 */
function planToPlanDrops(
  plan: PenCallPlan,
  dropsMap: CallFeedDropsMap,
  rationIdToDmRatio: RationToDryMatterRatio
): PlanDbDrops {
  switch (plan.method) {
    case PlanCallMethod.ByDrop:
      return byDropPlanToDb(plan, dropsMap);
    case PlanCallMethod.ByDistribution:
      return byDistributionPlanToDb(plan, dropsMap, rationIdToDmRatio);
    default:
      assertNever(plan);
  }
}

/** Given a one-time drop, convert it into a DropInput to send to the saveCallFeed mutation */
const oneTimeToDropInput = (d: DbOneTimeDrop): DropInput => {
  const dropInput: DropInput = {
    id: d.id,
    rationId: d.rationId,
    calledLbs: d.calledLbs,
    planCallAmount: undefined, // not used for one-time drops
    planCallUnit: undefined, // not used for one-time drops
    planDayOverride: undefined, // not used for one-time drops
  };
  return dropInput;
};

import { PenFeed } from '@/components/CallFeedTab/types';
import { useEffect, useState } from 'react';
import { SortableLotInfoBlock, sortableLotInfoBlocks } from '@/components/CallFeedTab/constants';
import useLocalStorage from '@/components/hooks/useLocalStorage';
import { SelectPicker } from '@/components/SelectPicker/SelectPicker';
import Box from '@mui/material/Box';
import { Subset } from '@/common/types/subset';
import { PenLot } from '@/web-types';
import { emDash } from '@/components/Typography/emDash';
import IconButton from '@mui/material/IconButton';
import { showLotInformationModal } from '@/components/CallFeedTab/LotInformationModal';
import { Subject } from '@mui/icons-material';
import { HeaderValue } from '@/components/CallFeedTab/PenDropsListHeaderLegacy';
import { Stack } from '@mui/material';

export const PenLotToolbar = ({
  pen,
  selectedPenLotId,
  setSelectedPenLotId,
}: {
  pen: PenFeed;
  selectedPenLotId: number;
  setSelectedPenLotId: (lotId: number) => void;
}) => {
  const lotsOptions = (pen.penLots ?? []).map((pl) => ({
    label: pl.lot?.name,
    onClick: () => setSelectedPenLotId(pl.id),
  }));
  const selectedPenLot = (pen.penLots ?? []).find((pl) => pl.id === selectedPenLotId)!;

  const [sortedLotInfoBlocks, setSortedLotInfoBlocks] = useState<SortableLotInfoBlock[]>([]);
  const [lotInfoBlocksOrderLS] = useLocalStorage<string[]>('lotInfoBlocksOrder', []);

  useEffect(() => {
    setSortedLotInfoBlocks(
      sortableLotInfoBlocks.sort((a, b) => {
        const aIndex = lotInfoBlocksOrderLS.indexOf(a.label);
        const bIndex = lotInfoBlocksOrderLS.indexOf(b.label);
        return aIndex - bIndex;
      })
    );
  }, [lotInfoBlocksOrderLS]);

  return (
    <Stack
      direction="row"
      spacing={2}
      display="flex"
      flex="1"
      justifyContent="space-between"
      alignItems="center"
      px={1}
      width="100%"
      height="46px"
      borderRadius={{ xs: 1, md: 0 }}
      mb={{ xs: 2, md: 0 }}
      sx={(theme) => ({ background: theme.palette.grey[300] })}
    >
      <SelectPicker
        items={lotsOptions}
        text={selectedPenLot?.lot?.name}
        sx={{
          color: '#2DA6AE',
          background: '#E2FDFF',
          padding: '1px 7px',
          borderRadius: '7px',
          border: '1px solid rgba(45, 166, 174, 0.7)',
          '&:hover': {
            background: '#D5F9FF',
          },
        }}
      />

      <Box flexGrow={1} overflow="auto">
        <Box whiteSpace="nowrap" display="flex" gap={4}>
          {sortedLotInfoBlocks.map(({ shortLabel, renderValue }) => (
            <HeaderValue key={shortLabel}>
              {shortLabel}: <b>{selectedPenLot ? renderValue(selectedPenLot as unknown as Subset<PenLot>) : emDash}</b>
            </HeaderValue>
          ))}
        </Box>
      </Box>

      <Box>
        <IconButton
          onClick={() =>
            showLotInformationModal({
              penLots: pen.penLots as unknown as PenLot[],
              defaultPenLotId: selectedPenLotId,
              onSuccess: (lotId) => setSelectedPenLotId(lotId),
            })
          }
        >
          <Subject />
        </IconButton>
      </Box>
    </Stack>
  );
};

import { useCallback } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { showConfirmationModal } from '../../ConfirmationModal/ConfirmationModal';

type DropDeleteButtonProps = {
  onDelete: (dropIndex: number) => void;
  index: number;
  'data-testid'?: string;
} & IconButtonProps;

export const DropDeleteButton = ({
  onDelete,
  index,
  'data-testid': dataTestId = 'drop-delete-button',
  ...iconButtonProps
}: DropDeleteButtonProps) => {
  const onDeleteDrop = useCallback(() => {
    showConfirmationModal({
      title: 'Delete Drop',
      message: 'Are you sure you want to delete this drop?',
      confirmButton: 'Delete',
      onConfirm: () => onDelete(index),
    });
  }, [onDelete, index]);
  return (
    <IconButton onClick={onDeleteDrop} data-testid={dataTestId} {...iconButtonProps}>
      <DeleteIcon color="primary" sx={{ fontSize: 16 }} />
    </IconButton>
  );
};

import { PlanCallUnit } from '@/web-types';

/**
 * Convert an amount from one plan unit to another
 * @param fromUnit The unit to convert from
 * @param toUnit The unit to convert to
 * @param amount The amount to convert
 * @param head The head count to use if needed
 * @param dryMatterRatio The dry matter ratio to use if needed
 * @returns
 */
export function planUnitConvert(
  amount: number,
  fromUnit: PlanCallUnit,
  toUnit: PlanCallUnit,
  head: number,
  dryMatterRatio: number
) {
  return amount * planUnitConversionFactor(fromUnit, toUnit, head, dryMatterRatio);
}

/**
 * Get a conversion factor for converting from one plan unit to another
 * @param fromUnit The unit to convert from
 * @param toUnit The unit to conver to
 * @param head The head count to use if needed
 * @param dryMatterRatio The dry matter ratio to use if needed
 * @returns
 */
export function planUnitConversionFactor(
  fromUnit: PlanCallUnit,
  toUnit: PlanCallUnit,
  head: number,
  dryMatterRatio: number
): number {
  const isFromPerHead = isPerHead(fromUnit);
  const isToPerHead = isPerHead(toUnit);
  const isFromDmi = isDmi(fromUnit);
  const isToDmi = isDmi(toUnit);
  const headFactor = isFromPerHead === isToPerHead ? 1 : isToPerHead ? 1 / head : head;
  const dmRatioFactor = isFromDmi === isToDmi ? 1 : isToDmi ? dryMatterRatio : 1 / dryMatterRatio;
  // infinite, here, means that a divisor was zero
  // this can happen, for example, if you were feeding as-fed an empty pen (0 head) and want to switch to per-head
  // when this happens, the most reasonable default behavior is to assume 0 per head.
  // it could happen with dmi only if you had a ration of 0% dry matter (water), but we'll handle that the same way
  return finiteOrZero(headFactor) * finiteOrZero(dmRatioFactor);
}

/** Whether a PlanCallUnit is per head */
export function isPerHead(planCallUnit: PlanCallUnit) {
  return planCallUnit === PlanCallUnit.AsFedLbsPerHead || planCallUnit === PlanCallUnit.DmiLbsPerHead;
}

/** Whether a PlanCallUnit specifies dry matter intake */
export function isDmi(planCallUnit: PlanCallUnit) {
  return planCallUnit === PlanCallUnit.DmiLbsPerHead;
}

function finiteOrZero(x: number) {
  return isFinite(x) ? x : 0;
}

import { useEffect, useMemo, useState } from 'react';
import { useEditContext, useEditContextPreventRouteChange } from '../EditContext';
import { UnsavedWarningDialog } from './UnsavedWarningDialog';

/**
 * This provides a very simple interface for adding out-click handling
 * To use it, just call the hook and then put the resulting dialog handle into the rendered return value of your component
 *
 * ```tsx
 * const MyComponent = () => {
 *   const unsavedDialog = useUnsavedWarningDialog(dirty, saveWorking, saving);
 *   return (
 *     <>
 *       <MyComponentThatNeedsToPreventRouteChange />
 *       {unsavedDialog}
 *     </>
 *   )
 * }
 * ```
 */
export const useUnsavedWarningDialog = (
  dirty: boolean,
  saveWorking: () => Promise<unknown>,
  saving: boolean = false,
  hasError: boolean = false
) => {
  const { setEditing, setPreventEvent, continueEvent } = useEditContext();
  const [dialogState, setDialogState] = useState<DialogState>();
  useEffect(() => {
    setEditing(dirty);
    setPreventEvent(() => {
      setDialogState({
        didRespondWith: (proceed: boolean) => {
          if (proceed) continueEvent();
        },
      });
    });
  }, [dirty, setEditing, setPreventEvent, continueEvent]);
  useEditContextPreventRouteChange();

  const unsavedDialog = useMemo(() => {
    if (!dialogState) return null;
    const { didRespondWith } = dialogState;
    const closeDialog = () => setDialogState(undefined);
    const cancelAction = () => {
      // Just cancel whatever action the user was about to do that prompted the dialog
      didRespondWith(false);
      closeDialog();
    };
    const discardChangesAndContinue = () => {
      // User doesn't care about the changes, so just continue with the action the user was about to do
      didRespondWith(true);
      closeDialog();
    };
    const saveChangesAndContinue = async () => {
      // User wants to save the changes before continuing with the action he was about to do
      await saveWorking();
      didRespondWith(true);
      closeDialog();
    };
    return (
      <UnsavedWarningDialog
        hasError={hasError}
        saving={saving}
        onClose={cancelAction}
        open={true}
        onDiscard={discardChangesAndContinue}
        onContinueEditing={cancelAction}
        onSave={saveChangesAndContinue}
      />
    );
  }, [dialogState, saveWorking, saving, hasError]);

  return unsavedDialog;
};

type DialogState = {
  didRespondWith: (result: boolean) => void;
};

import { sumBy } from 'lodash';
import { localdate } from '@/common/models/types';
import { assertImpossible } from '../utils/assertImpossible';
import { memoized } from '../utils/memoized';
import { Ingredient, Ration } from '@/web-types';
import { Subset } from '@/common/types/subset';

export type RationToDryMatterRatio = (rationId: number) => number;

/** Returns a function that will calculate the dry matter ratio of an ingredient for a given date of feeding */
export function ingredientDryMatterRatio(feedDate?: localdate): (ingredient: Subset<Ingredient>) => number {
  return feedDate
    ? (i) =>
        'histories' in i
          ? (i.histories ?? []).find((h) => h?.fromDate! <= feedDate && (!h?.toDate || h?.toDate! > feedDate))!
              .percentDry!
          : i.current?.percentDry!
    : (i) =>
        'current' in i ? i.current!.percentDry! : (i.histories ?? []).find((h) => h?.toDate === null)!.percentDry!;
}

export function getRationCurrentRevisionToDryMatterRatio(
  rations: readonly Subset<Ration>[],
  feedDate?: localdate
): RationToDryMatterRatio {
  const map = new Map(
    rations.map((ration) => {
      const calcDmRatio = () => {
        // get the current ingredients for this ration
        const revision = ration.currentRevision;
        const ingredients = (ration.ingredients ?? []).filter((ingredient) => ingredient?.rationRevision === revision);
        const wholePct = Math.round(sumBy(ingredients, (ingredient) => (ingredient?.percentOfRationAsFed ?? 0) * 100));
        if (100 != wholePct)
          throw new Error(`ingredients of ration ${ration.name} sum to ${wholePct}% instead of 100%`);
        const ingredientDmRatioForDate = ingredientDryMatterRatio(feedDate);
        const dmRatio = sumBy(
          ingredients,
          (ingredient) => ingredientDmRatioForDate(ingredient?.ingredient!) * (ingredient?.percentOfRationAsFed ?? 0)
        );
        return dmRatio;
      };
      const getOrCalcDmRatio = memoized(calcDmRatio);
      return [ration.id, getOrCalcDmRatio];
    })
  );
  return (id) =>
    (map.get(id) ?? assertImpossible(`tried to get dry matter ratio for a ration ${id} that was not found`))();
}

import Box, { BoxProps } from '@mui/material/Box';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { IconButton } from '@mui/material';
import { showObservationsModal } from '@/components/CallFeed/ObservationsModal';
import { BunkScoreValue } from '@/web-types';
import { bunkScoreLabel } from '@/common/dictionaries/bunkScore';
import { Input } from '../types';

type ObservationsBoxProps = BoxProps & {
  bunkScore: Input<BunkScoreValue | undefined>;
};
export const ObservationsBox: React.FC<ObservationsBoxProps> = ({ bunkScore, ...props }) => {
  return (
    <Box
      {...props}
      display="flex"
      sx={{
        background: 'white',
        border: `1px solid #CED4DA`,
        borderRadius: 1,
        padding: `3px 10px`,
        gap: 2,
        alignItems: 'center',
        ...props.sx,
      }}
    >
      <Box display="flex" flex={1} gap={2}>
        <Box data-testid="observations-box">
          Bunk Score: <span data-testid="bunk-score">{bunkScore.value ? bunkScoreLabel(bunkScore.value) : '--'}</span>
        </Box>
        {/*
        <Box>Aggression --</Box>
        <Box>Notes --</Box>
        */}
      </Box>
      <IconButton
        onClick={() =>
          // TODO: Propagate real value once we have it
          showObservationsModal(bunkScore)
        }
        sx={{ padding: 0 }}
        data-testid="bunk-score-edit-button"
      >
        <ModeOutlinedIcon />
      </IconButton>
    </Box>
  );
};

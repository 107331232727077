import { callFeedV2CookieName } from '@/common/config';
import { CALL_FEED_V2 } from '@/components/Providers/config';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCookies } from 'react-cookie';

export const useCallFeedFeatureFlags = () => {
  const { callFeedV2, callFeedV2Detailed } = useFlags();
  const [cookies] = useCookies([callFeedV2CookieName]);

  // If the cookie is set we use it.
  // If not, if the environment variable is set we use it
  // If not, we use the LaunchDarkly flags

  const setting = cookies[callFeedV2CookieName] ?? CALL_FEED_V2;
  if (setting) {
    return {
      useCallFeedV2: setting === 'enabled',
      useCallFeedV2DetailedMode: setting === 'detail-only',
    };
  }

  // Use LuanchDarkly flags
  return {
    useCallFeedV2: callFeedV2,
    useCallFeedV2DetailedMode: callFeedV2Detailed,
  };
};

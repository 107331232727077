import { localdate } from '@/common/models/types';
import { LoadStage, PlanCallUnit } from '@/web-types';
import { useMemo } from 'react';
import { PlanCallMethod } from '@/web-types';
import { PenIdType } from '@/common/types/idAliases';
import { CallFeedPenData, CallFeedPenReadonlyData, CallFeedPensData, PlanAndObservations } from './types';
import { addDays } from '@/common/utils/date';
import assertNever from 'assert-never';
import { isPresent } from 'ts-is-present';
import { CallFeedDropIdAndActual } from '../../callPlan';
import { planUnitConvert } from '@/common/callFeed/planCallUnit';
import {
  DbRecurringDrop,
  DbOneTimeDrop,
  PlanDbDrops,
  byDropPlanFromDb,
  byDistributionPlanFromDb,
} from '../../callPlan/toFromDb';
import { DbDrop, DbPen } from '../../CallFeedTab';

type CalculatePenDataArgs = {
  pen: DbPen;
  today: localdate;
  callUnit: PlanCallUnit;
  callMethod: PlanCallMethod;
  duplicationHasOccurred: boolean;
};

type PenDropsByDate = {
  yesterdayDropsMap: readonly CallFeedDropIdAndActual[];
  todayDropsMap: readonly CallFeedDropIdAndActual[];
  tomorrowDropsMap: readonly CallFeedDropIdAndActual[];
  yesterdayRecurring: readonly DbRecurringDrop[];
  todayOneTime: readonly DbOneTimeDrop[];
  yesterdayOneTime: readonly DbOneTimeDrop[];
  todayRecurring: readonly DbRecurringDrop[];
  tomorrowRecurring: readonly DbRecurringDrop[];
};

/** Return the data ultimately needed by the Call Feed UI */
export const useCallFeedData = (
  pens: DbPen[],
  today: localdate,
  callUnit: PlanCallUnit,
  callMethod: PlanCallMethod,
  // TODO: when we are capable of by-pen duplication, each pen in pens should have its own duplicationHasOccurred
  duplicationHasOccurred: boolean
): CallFeedPensData => {
  return useMemo(() => {
    const readonly = new Map<PenIdType, CallFeedPenReadonlyData>();
    const mutable = new Map<PenIdType, PlanAndObservations>();
    for (const pen of pens) {
      const penData = calculatePenData({
        pen,
        today,
        callUnit,
        callMethod,
        duplicationHasOccurred,
      });
      readonly.set(pen.id, penData);
      mutable.set(pen.id, penData.planAndObservations);
    }
    return { readonly, mutable };
  }, [callMethod, callUnit, pens, today, duplicationHasOccurred]);
};

const calculatePenData = ({
  pen,
  today,
  callUnit,
  callMethod,
  duplicationHasOccurred,
}: CalculatePenDataArgs): CallFeedPenData => {
  // Separate the drops by date and convert the units
  const drops = separateAndConvertDrops(pen.drops!, today, callUnit);
  const {
    yesterdayRecurring,
    yesterdayDropsMap,
    todayRecurring,
    todayOneTime,
    todayDropsMap,
    tomorrowRecurring,
    tomorrowDropsMap,
  } = drops;

  // Create the main plan structure for today and tomorrow
  const planDrops = {
    callUnit,
    today: { recurring: todayRecurring, oneTime: todayOneTime },
    tomorrow: tomorrowRecurring,
    duplicationHasOccurred,
  };
  const plan = planDropsToPlan(planDrops, callMethod);

  // Create yesterday's plan also to use in calculation of day-over-day deltas
  const planYesterday = singleDayDropsToPlan(yesterdayRecurring, callMethod, callUnit);

  // Return the final populated data structure
  const planAndObservations = {
    plan,
    observationsToday: {
      bunkScore: pen?.bunkScores?.find((score) => score.date === today)?.bunkScore ?? undefined,
    },
  };
  return {
    penId: pen.id,
    yesterdayDropsMap,
    numHead: pen.numHeads ?? 0,
    numHeadYesterday: pen.numHeadsEndOfYesterday ?? 0,
    currentWeightPerHeadLbs: pen.currentWeightPerHeadLbs ?? null,
    yesterdayWeightPerHeadLbs: pen.yesterdayWeightPerHeadLbs ?? null,
    planYesterday,
    planAndObservations,
    dropsMap: { today: todayDropsMap, tomorrow: tomorrowDropsMap, duplicationHasOccurred },
    penName: pen.name,
    penLots: pen.activePenLots! as CallFeedPenData['penLots'], // needed for now because of the nullability of graphql 'lot' :(,
  };
};

/**
 * Processes raw drops from the backend.
 * It takes care of
 * 1. separating the drops by day
 * 2. creates a drops map for each day
 * 3. separating the drops into recurring/one-time
 * 4. converts to the target units
 * 5. removes nullablility of some fields
 * @param dbDrops
 * @param today
 * @param callUnit
 * @returns
 */
const separateAndConvertDrops = (
  dbDrops: readonly DbDrop[],
  today: localdate,
  callUnit: PlanCallUnit
): PenDropsByDate => {
  // separtae by day and recurring/one-time
  const { yesterdayRecurring, yesterdayOneTime, todayRecurring, todayOneTime, tomorrowRecurring, remainder } = separate(
    dbDrops,
    today,
    callUnit
  );
  if (remainder.length) {
    // We have separated the drops above into categories
    // Every drop must go to one of these categories or something is wrong
    console.error('Uncategorized drops:', remainder);
    // Don't throw here, beacuse there is an edge case in which this is expected. When the day has changed (so `today`
    // has changed in `useCallFeedData({ today })`), but we haven't yet received the updated data for the new `today`
    // (the data gets refetched as a result of `today` changing but the data isn't received instantly), then `today`
    // and `rawDropsByPen` mismatch (e.g. `rawDropsByPen` contains drops for day-before-yesterday). This creates a
    // non-empty `remainder` array.
  }
  // also get drops maps separated by day
  const yesterday = addDays(today, -1);
  const tomorrow = addDays(today, 1);
  const dropsYesterday = dbDrops.filter((d) => d.date === yesterday);
  const dropsToday = dbDrops.filter((d) => d.date === today);
  const dropsTomorrow = dbDrops.filter((d) => d.date === tomorrow);
  const yesterdayDropsMap = dropsYesterday.map(dropToActual).filter(isPresent);
  const todayDropsMap = dropsToday.map(dropToActual).filter(isPresent);
  const tomorrowDropsMap = dropsTomorrow.map(dropToActual).filter(isPresent);
  return {
    yesterdayDropsMap,
    todayDropsMap,
    tomorrowDropsMap,
    yesterdayRecurring,
    todayOneTime,
    yesterdayOneTime,
    todayRecurring,
    tomorrowRecurring,
  };
};

const separate = (dbDrops: readonly DbDrop[], today: localdate, callUnit: PlanCallUnit) => {
  type Recurring = NonNullable<ReturnType<typeof toRecurringDrop>>;
  type OneTime = NonNullable<ReturnType<typeof toOneTimeDrop>>;
  const yesterday = addDays(today, -1);
  const tomorrow = addDays(today, 1);
  const yesterdayRecurring: Recurring[] = [];
  const todayRecurring: Recurring[] = [];
  const tomorrowRecurring: Recurring[] = [];
  const yesterdayOneTime: OneTime[] = [];
  const todayOneTime: OneTime[] = [];
  const remainder: DbDrop[] = [];
  for (const item of dbDrops) {
    const recurringDrop = toRecurringDrop(item, callUnit);
    if (recurringDrop !== undefined) {
      if (item.date === today) todayRecurring.push(recurringDrop);
      else if (item.date === tomorrow) tomorrowRecurring.push(recurringDrop);
      else if (item.date === yesterday) yesterdayRecurring.push(recurringDrop);
      else remainder.push(item);
      continue;
    }
    const oneTimeDrop = toOneTimeDrop(item);
    if (oneTimeDrop !== undefined) {
      if (item.date === today) todayOneTime.push(oneTimeDrop);
      else if (item.date === yesterday) yesterdayOneTime.push(oneTimeDrop);
      else remainder.push(item);
      continue;
    }
    remainder.push(item);
  }
  return {
    yesterdayRecurring,
    yesterdayOneTime,
    todayRecurring,
    todayOneTime,
    tomorrowRecurring,
    remainder,
  } as const;
};

/**
 * Converts database format plan drops to a feed plan
 * @param planDrops - Plan drops from database
 * @param callMethod - Method used for feed calls
 * @returns Converted feed plan
 */
function planDropsToPlan(planDrops: PlanDbDrops, callMethod: PlanCallMethod) {
  switch (callMethod) {
    case PlanCallMethod.ByDrop:
      return byDropPlanFromDb(planDrops);
    case PlanCallMethod.ByDistribution:
      return byDistributionPlanFromDb(planDrops);
    default:
      assertNever(callMethod);
  }
}

function singleDayDropsToPlan(
  singleDayRecurring: readonly DbRecurringDrop[],
  callMethod: PlanCallMethod,
  callUnit: PlanCallUnit
) {
  const planDrops = {
    callUnit,
    today: {
      recurring: singleDayRecurring,
      oneTime: [],
    },
    tomorrow: [],
    duplicationHasOccurred: false,
  };
  switch (callMethod) {
    case PlanCallMethod.ByDrop:
      return byDropPlanFromDb(planDrops);
    case PlanCallMethod.ByDistribution:
      return byDistributionPlanFromDb(planDrops);
    default:
      assertNever(callMethod);
  }
}

/** Convert a raw database drop into a recurring drop structure, converting the units if necessary */
const toRecurringDrop = (drop: DbDrop, toCallUnit: PlanCallUnit): DbRecurringDrop | undefined => {
  if (drop.planCallUnit == null || (drop.planCallAmount ?? drop.planDayOverride) == null) return undefined;
  const { planCallAmount, planDayOverride, planCallUnit: fromCallUnit, numHeads, dryMatterRatio, rationId } = drop;
  return {
    id: drop.id,
    amount:
      planCallAmount == null
        ? null
        : planUnitConvert(planCallAmount, fromCallUnit, toCallUnit, numHeads ?? 0, dryMatterRatio ?? 1),
    override:
      planDayOverride == null
        ? null
        : planUnitConvert(planDayOverride, fromCallUnit, toCallUnit, numHeads ?? 0, dryMatterRatio ?? 1),
    rationId: drop.rationId,
  };
};

/** Convert a raw database drop into a one-time drop structure */
const toOneTimeDrop = (drop: DbDrop): DbOneTimeDrop | undefined => {
  if (drop.planCallAmount != null || drop.planCallUnit != null || drop.calledLbs == null) return undefined;
  return {
    id: drop.id,
    calledLbs: drop.calledLbs,
    rationId: drop.rationId,
  };
};

/** Given a raw database drop, convert it into a CallFeedDropIdAndActual structure */
const dropToActual = (rawDrop: DbDrop): CallFeedDropIdAndActual => {
  const id = {
    dropId: rawDrop.id,
    oneTime: rawDrop.planCallUnit == null,
  };

  const hasActual = rawDrop.actualCalledLbs != null || rawDrop.fedOn != null || rawDrop.fedLbs != 0;

  // For batch feeders, this is always false because `rawDrop.load` is null.
  // TODO: (batch feeding) set `isLoadStarted` to true if _any_ load for this drop is started
  const isLoadStarted = !!rawDrop.load && [LoadStage.InProgress, LoadStage.Completed].includes(rawDrop.load?.stage);

  return {
    ...id,
    isLoadStarted,
    actual: !hasActual
      ? undefined
      : {
          rationId: rawDrop.rationId,
          head: rawDrop.numHeads ?? 0,
          afLbsCalled: rawDrop.actualCalledLbs!, // TODO: determine how to handle actuals with fed but no actualized call (batch feeders)
          afLbsFed: rawDrop.fedLbs,
        },
  };
};

import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { CallFeedPenReadonlyData, PlanAndObservations } from './hooks/useCallFeedData';
import { useCallFeedUiState } from './hooks/useCallFeedUiState';
import { RationToDryMatterRatio } from '@/common/models/ration';
import { FeedingMethod } from '@/web-types';
import { RationOption } from './hooks/useRations';
import { formatNumber } from '@/common/utils/format';
import { CallAmount, Day } from './callPlan';
import { ObservationsBox } from './ObservationsBox';
import { IncrementalNumberInput } from './controls/IncrementalNumberInput';
import React from 'react';
import { RationInput } from './controls/RationInput';
import { CallAmountInput } from './controls/CallAmountInput';
import { Input } from '../types';
import { LinkButton } from '../LinkButton/LinkButton';
import { InfoOutlined } from '@mui/icons-material';
import { showLotsInPenModal } from '../CallFeedTab/LotsInPenModal';
import { DropDeleteButton } from './controls/DropDeleteButton';
import { SimpleViewEditDropsButton } from './controls/SimpleViewEditDropsButton';

export type CallFeedSinglePenSimpleViewProps = {
  penId: number;
  penData: CallFeedPenReadonlyData;
  workingData: PlanAndObservations;
  rations: readonly RationOption[];
  setWorkingData: (fn: (prev: PlanAndObservations) => PlanAndObservations) => void;
  rationIdToDmRatio: RationToDryMatterRatio;
  feedingMethod: FeedingMethod;
  day: Day;
  increments: { dmiPerHead: number; afPerHead: number; af: number };
  showDetailedView: () => void;
};
export const CallFeedSinglePenSimpleView: React.FC<CallFeedSinglePenSimpleViewProps> = React.memo(
  ({
    penId,
    penData,
    workingData,
    rations,
    setWorkingData,
    rationIdToDmRatio,
    feedingMethod,
    day,
    increments,
    showDetailedView,
  }) => {
    const {
      numHeadYesterday: yesterdayNumHead,
      numHead: currentHead,
      yesterdayDropsMap: dropsMapYesterday,
      dropsMap: actual,
      planYesterday,
      yesterdayWeightPerHeadLbs,
      currentWeightPerHeadLbs,
      penName,
    } = penData;

    // Convert our working data and the uneditable state into a UI state
    const state = useCallFeedUiState({
      yesterdayNumHead,
      currentHead,
      rationIdToDmRatio,
      planYesterday,
      dropsMapYesterday,
      data: workingData,
      setData: setWorkingData,
      dropsMap: actual,
      feedingMethod,
      yesterdayWeightPerHeadLbs,
      currentWeightPerHeadLbs,
      defaultRationId: rations[0].id,
    });

    const currentDay = state[day];

    return (
      <Grid title={penName} sx={{ m: 2 }} style={{ backgroundColor: '#F8F9FA', borderRadius: 8, overflow: 'hidden' }}>
        <Box
          display="flex"
          flexDirection="row"
          borderBottom="1px solid #DEE2E6"
          gap={3}
          py={{ xs: 1, sm: 0.7 }}
          px={2}
          sx={{ backgroundColor: '#E9ECEF' }}
        >
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            gap={{ xs: 0.5, sm: 3 }}
            flex={{ xs: 1, sm: 'none' }}
          >
            <Box sx={{ fontSize: 18, fontWeight: 'bold', color: 'red.500', flex: { xs: 1, sm: 'none' } }}>
              {penName}
            </Box>
            {day == 'today' ? (
              <ObservationsBox
                sx={{ width: { xs: 'auto', sm: 180 }, flex: { xs: 1, sm: 'none' } }}
                bunkScore={state.observations.bunkScore}
              />
            ) : (
              <Box height={32} />
            )}
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'flex-end', sm: 'center' }}
            flex={1}
            gap={{ xs: 0.5, sm: 3 }}
            justifyContent="space-between"
          >
            <Stack direction="row" style={{ flex: 1, gap: 8, alignItems: 'baseline', whiteSpace: 'nowrap' }}>
              <span>{penData.numHead} HD</span>
              {penData.currentWeightPerHeadLbs != null ? (
                <>
                  <span>|</span>
                  <span>{Math.round(penData.currentWeightPerHeadLbs)} lbs</span>
                </>
              ) : null}
              <span>|</span>
              <LinkButton endIcon={<InfoOutlined />} onClick={() => showLotsInPenModal({ penId })}>
                Lots
              </LinkButton>
            </Stack>
            <Button
              variant="text"
              data-testid="call-feed-pen-show-details"
              onClick={showDetailedView}
              sx={{ p: 0, minWidth: 0, flex: { xs: 1, sm: 'none' } }}
            >
              Show Details
            </Button>
          </Box>
        </Box>
        <Stack direction={{ xs: 'column', sm: 'row' }} style={{ backgroundColor: '#EFF1F3' }}>
          <Stack
            direction={{ xs: 'row', sm: 'column' }}
            data-testid="per-head"
            pl={{ xs: 2, sm: 2 }}
            pr={{ xs: 2, sm: 2 }}
            py={{ xs: 2, sm: 1 }}
            sx={{ width: { xs: 'auto', sm: 246 }, gap: 1, background: day == 'today' ? '#EFF1F3' : '#CEE7FE' }}
          >
            <Typography display={{ xs: 'none', sm: 'block' }}>&nbsp;</Typography>
            <Stack
              flex={{ xs: 1, sm: 'none' }}
              direction={{ xs: 'column', sm: 'row' }}
              data-testid="as-fed-per-head"
              alignItems="center"
              gap={{ xs: 0.5, sm: 1 }}
            >
              <Box
                textAlign={{ xs: 'left', sm: 'center' }}
                width={{ xs: '100%', sm: 85 }}
                pl={{ xs: 0.5, sm: 0 }}
                fontWeight="bold"
              >
                AF/HD
              </Box>
              <IncrementalNumberInput
                data-testid="call-feed-simple-pen-asFedPerHead"
                increment={increments.afPerHead}
                {...currentDay.afLbsPerHead}
              />
            </Stack>
            <Stack
              flex={{ xs: 1, sm: 'none' }}
              direction={{ xs: 'column', sm: 'row' }}
              data-testid="dmi-per-head"
              alignItems="center"
              gap={{ xs: 0.5, sm: 1 }}
            >
              <Box
                textAlign={{ xs: 'left', sm: 'center' }}
                width={{ xs: '100%', sm: 85 }}
                pl={{ xs: 0.5, sm: 0 }}
                fontWeight="bold"
              >
                DMI/HD
              </Box>
              <IncrementalNumberInput
                data-testid="call-feed-simple-pen-dmiPerHead"
                increment={increments.dmiPerHead}
                {...currentDay.dmiLbsPerHead}
              />
            </Stack>
          </Stack>
          <Stack
            py={{ xs: 2, sm: 1 }}
            px={{ xs: 2, sm: 2 }}
            sx={{ background: day == 'today' ? '#EFF1F3' : '#CEE7FE' }}
            data-testid="pen-total"
            width={{ xs: '100%', sm: 200 }}
            flexWrap="wrap"
            flexDirection="row"
            gap={{ xs: 0, sm: 1 }}
          >
            <Box
              width={{ xs: '50%', sm: '100%' }}
              textAlign={{ xs: 'left', sm: 'center' }}
              pl={{ xs: 0.5, sm: 0 }}
              pb={{ xs: 0.5, sm: 0 }}
              fontWeight="bold"
              order={0}
            >
              Total Call AF
            </Box>
            <Box width={{ xs: '50%', sm: '100%' }} order={10} pr={{ xs: 0.5, sm: 0 }}>
              <RationInput
                rations={rations}
                {...currentDay.rationId}
                data-testid="call-feed-simple-pen-ration-select-field"
              />
            </Box>
            <Box width={{ xs: '50%', sm: '100%' }} order={20} pl={{ xs: 0.5, sm: 0 }}>
              <IncrementalNumberInput
                data-testid="call-feed-simple-drops-total-called"
                increment={increments.af}
                {...currentDay.afLbs}
                decimals={0}
              />
            </Box>
            <Box
              data-testid="call-feed-simple-drops-total-fed"
              width={{ xs: '50%', sm: '100%' }}
              textAlign={{ xs: 'right', sm: 'center' }}
              order={{ xs: 5, sm: 30 }}
              color="#878E95"
            >
              {formatNumber(currentDay.fedLbs)} Fed
            </Box>
          </Stack>
          <Stack
            pl={{ xs: 0, sm: 2 }}
            py={{ xs: 2, sm: 0 }}
            direction={{ xs: 'column', sm: 'row' }}
            data-testid="pen-drops"
            flex={1}
            gap={2}
            sx={{
              overflowX: 'auto',
              backgroundColor: day == 'today' ? '#F8F9FA !important' : '#E7F3FE',
              borderLeft: { xs: 'none', sm: '1px solid #DEE2E6' },
              borderTop: { xs: '1px solid #DEE2E6', sm: 'none' },
            }}
          >
            {currentDay.drops.map((drop, i) => (
              <SingleDropView
                key={drop.dropId ?? `drop-${i}`}
                oneTime={false}
                index={i}
                rationId={drop.rationId}
                lbsCalled={drop.lbsCalled}
                lbsFed={drop.lbsFed}
                rations={rations}
                increment={increments.af}
                onDelete={drop.onDelete}
              />
            ))}
            {currentDay.oneTimeDrops.map((drop, i) => (
              <SingleDropView
                key={drop.id ?? `onetime-drop-${i}`}
                oneTime={true}
                index={i}
                rationId={drop.rationId}
                lbsCalled={drop.lbsCalled}
                lbsFed={drop.lbsFed}
                rations={rations}
                increment={increments.af}
                onDelete={drop.onDelete}
              />
            ))}
            <Box justifyContent="center" style={{ display: 'flex', alignItems: 'center' }}>
              {state.distributionPercents?.onChange || currentDay.onAddRecurringDrop || currentDay.onAddOneTimeDrop ? (
                <SimpleViewEditDropsButton
                  defaultRationId={rations[0].id}
                  onAddRecurringDrop={currentDay.onAddRecurringDrop}
                  onAddOneTimeDrop={currentDay.onAddOneTimeDrop}
                  distributionPercents={state.distributionPercents}
                  onResetOverrides={currentDay.onResetOverrides}
                />
              ) : null}
            </Box>
          </Stack>
        </Stack>
      </Grid>
    );
  }
);

type SingleDropViewProps = {
  index: number;
  rationId: Input<number>;
  lbsFed: number;
  increment: number;
  rations: readonly RationOption[];
  onDelete?: (dropIndex: number) => void;
} & (
  | {
      oneTime: false;
      lbsCalled: Input<CallAmount>;
    }
  | { oneTime: true; lbsCalled: Input<number> }
);
const SingleDropView: React.FC<SingleDropViewProps> = ({
  rations,
  rationId,
  lbsCalled,
  index,
  lbsFed,
  increment,
  onDelete,
  oneTime,
}) => {
  const dropNumber = index + 1;
  return (
    <Stack
      py={1}
      px={{ xs: 2, sm: 0 }}
      data-testid={`pen-drop-${oneTime ? 'onetime-' : ''}${dropNumber}`}
      direction="column"
      flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
      flexDirection={{ xs: 'row', sm: 'column' }}
      gap={{ xs: 0, sm: 1 }}
      width={{ xs: '100%', sm: 176 }}
      minWidth={{ xs: '100%', sm: 176 }}
    >
      <Stack
        order={0}
        width={{ xs: '50%', sm: 'auto' }}
        pb={{ xs: 0.5, sm: 0 }}
        direction="row"
        textAlign={{ xs: 'left', sm: 'center' }}
        style={{ gap: 2 }}
      >
        <Box textAlign={{ xs: 'left', sm: 'center' }} style={{ fontWeight: 'bold', flex: 1 }} order={{ xs: 10, sm: 0 }}>
          {oneTime ? '1 Time Drop' : `Drop ${dropNumber}`}
        </Box>
        {onDelete && (
          <DropDeleteButton
            onDelete={onDelete}
            index={index}
            data-testid="simple-drop-delete-button"
            style={{ margin: 0, padding: 0 }}
          />
        )}
      </Stack>

      <Box order={10} width={{ xs: '50%', sm: 'auto' }} pr={{ xs: 0.5, sm: 0 }}>
        <RationInput rations={rations} {...rationId} data-testid="call-feed-simple-drop-ration-select-field" />
      </Box>
      <Box order={20} width={{ xs: '50%', sm: 'auto' }} pl={{ xs: 0.5, sm: 0 }}>
        {oneTime ? (
          <IncrementalNumberInput
            data-testid="call-feed-simple-drop-onetime-calledLbs"
            increment={increment}
            decimals={0}
            {...lbsCalled}
          />
        ) : (
          <CallAmountInput data-testid="call-feed-simple-drop-calledLbs" increment={increment} {...lbsCalled} />
        )}
      </Box>
      <Box
        data-testid="call-feed-simple-drop-fedLbs"
        width={{ xs: '50%', sm: 'auto' }}
        order={{ xs: 5, sm: 30 }}
        textAlign={{ xs: 'right', sm: 'center' }}
        style={{ color: '#878E95' }}
      >
        {formatNumber(lbsFed)} Fed
      </Box>
    </Stack>
  );
};

import { RationToDryMatterRatio } from '@/common/models/ration';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { IncrementalNumberInput } from './controls/IncrementalNumberInput';
import { RationInput } from './controls/RationInput';
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  ModalProps,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { FeedingMethod } from '@/web-types';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { CallAmountInput } from './controls/CallAmountInput';
import { DialogActions, DialogContainer, DialogContent, DialogTitle } from '@/components/Dialog';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import LaunchIcon from '@mui/icons-material/Launch';
import { PenChangeStack, PenChangeStackProps } from '@/components/CallFeed/PenChangeStack';
import { TodayTomorrowStack } from '@/components/CallFeed/TodayTomorrowStack';
import SettingsIcon from '@mui/icons-material/Settings';
import { showCallFeedSettingsModal } from '@/components/CallFeedTab/CallFeedSettingsModal';
import { PenLotToolbar } from '@/components/CallFeed/PenLotToolbar';
import { Day } from '@/components/CallFeed/callPlan';
import { useCallFeedUiState } from '@/components/CallFeed/hooks/useCallFeedUiState';
import { RationOption } from '@/components/CallFeed/hooks/useRations';
import { PenHistoryBox } from '@/components/CallFeed/PenHistoryBox';
import { Error, KeyboardReturn } from '@mui/icons-material';
import { ObservationsBox } from '@/components/CallFeed/ObservationsBox';
import { FeedPlanBox } from '@/components/CallFeed/FeedPlanBox';
import { DuplicateDropsEmptyView } from '@/components/CallFeedTab/DuplicateDropsEmptyView';
import { formatDateForDB } from '@/components/helpers/format';
import ModalManager, { useModal } from '@ebay/nice-modal-react';
import { LoadingButton } from '@mui/lab';
import { useIsPhoneSize } from '../hooks/useResponsive';
import { today } from '../helpers/date';
import { CallFeedPenReadonlyData, PlanAndObservations, SaveResult, SaveWorking } from './hooks/useCallFeedData';
import { DropDeleteButton } from './controls/DropDeleteButton';
import { DistributionEditor } from './controls/DistributionEditor';

const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '5px',
    marginBottom: '5px',
  },
  [theme.breakpoints.up('md')]: {
    display: 'table-row',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '5px 10px !important',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    height: 'auto !important',
    borderBottom: 'none',
  },
}));

const StyledTableCellHeader = styled(StyledTableCell)(() => ({
  fontWeight: 'bold',
  width: 'auto',
}));

export const CallFeedPlan = ({
  penId,
  penData,
  penList,
  workingData,
  rations,
  haveTomorrow,
  saving,
  setWorkingData,
  saveWorking,
  rationIdToDmRatio,
  didDuplicate,
  feedingMethod,
  increments,
  onExit,
  onChangePen,
  day,
  setDay,
  dirty,
}: {
  penId: number;
  penData: CallFeedPenReadonlyData;
  // TODO: refactor this so that PenChangeStack is not a child of CallFeedPlan
  penList: PenChangeStackProps['penList'];
  workingData: PlanAndObservations;
  rations: readonly RationOption[];
  haveTomorrow: boolean;
  saving: boolean;
  setWorkingData: (fn: (prev: PlanAndObservations) => PlanAndObservations) => void;
  saveWorking: SaveWorking;
  rationIdToDmRatio: RationToDryMatterRatio;
  didDuplicate: () => void;
  feedingMethod: FeedingMethod;
  increments: { dmiPerHead: number; afPerHead: number; af: number };
  onExit?: () => void;
  /** Weight per head of the pen yesterday */
  onChangePen: (penId: number) => void;
  day: Day;
  setDay: (day: Day) => void;
  dirty: boolean;
}) => {
  const isPhoneSize = useIsPhoneSize();
  const _today = dayjs.tz();
  const _tomorrow = _today.add(1, 'day');

  const {
    numHeadYesterday: yesterdayNumHead,
    numHead: currentHead,
    yesterdayDropsMap: dropsMapYesterday,
    dropsMap: actual,
    planYesterday,
    yesterdayWeightPerHeadLbs,
    currentWeightPerHeadLbs,
  } = penData;

  const [selectedPenLotId, setSelectedPenLotId] = useState<number | undefined>();
  const [showEditDropDistributionModal, setShowEditDropDistributionModal] = useState(false);
  // Convert our working data and the uneditable state into a UI state
  const state = useCallFeedUiState({
    yesterdayNumHead,
    currentHead,
    rationIdToDmRatio,
    planYesterday,
    dropsMapYesterday,
    data: workingData,
    setData: setWorkingData,
    dropsMap: actual,
    feedingMethod,
    yesterdayWeightPerHeadLbs,
    currentWeightPerHeadLbs,
    defaultRationId: rations[0].id,
  });

  const save = useCallback(async () => {
    const result = await saveWorking();
    if (result !== 'success') {
      const response = await showSaveFailedModal(result.error.graphQLErrors[0].message);
      if (response === 'undo-and-retry') {
        setWorkingData((_) => workingData);
      }
    }
    return result;
  }, [saveWorking, workingData, setWorkingData]);

  const currentDay = state[day];

  const dropCount = Math.max(state.today.drops.length, state.tomorrow.drops.length);

  const saveAndExit = useCallback(async () => {
    if (dirty) {
      const result = await save();
      if (result !== 'success') {
        return;
      }
    }
    onExit?.();
  }, [dirty, onExit, save]);

  const saveAndChangePen = useCallback(
    async (penId: number) => {
      if (dirty) {
        const result = await save();
        if (result !== 'success') {
          return;
        }
      }
      onChangePen(penId);
    },
    [dirty, onChangePen, save]
  );

  const { penLots } = penData;
  const selectedPenLot = useMemo(() => {
    if (!selectedPenLotId) return undefined;
    return penLots.find((p) => p.id === selectedPenLotId);
  }, [selectedPenLotId, penLots]);

  const onDayChange = useCallback(
    (value: 'today' | 'tomorrow') => {
      if (value === 'tomorrow' && dirty && !haveTomorrow) {
        confirmSave(save).then((confirmed) => {
          if (confirmed) setDay(value);
        });
        return;
      }
      setDay(value);
    },
    [dirty, haveTomorrow, save, setDay]
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', py: 1, px: 2 }}>
        <PenChangeStack penId={penId} onChangePen={saveAndChangePen} penList={penList} />

        <Box display="flex" alignItems="center" gap={1}>
          {!isPhoneSize && <TodayTomorrowStack value={day} onChange={onDayChange} />}
          <IconButton onClick={() => showCallFeedSettingsModal({ defaultTabIndex: 1 })}>
            <SettingsIcon />
          </IconButton>
          <Button
            sx={{ minWidth: 0 }}
            variant="text"
            color="inherit"
            startIcon={<KeyboardReturn />}
            disabled={saving}
            onClick={saveAndExit}
            data-testid="save-and-exit-button"
          >
            {!isPhoneSize && <>{saving ? 'Saving ...' : dirty ? 'Save And Exit' : 'Exit'}</>}
          </Button>
        </Box>
      </Box>

      {isPhoneSize && (
        <Box p={1}>
          <TodayTomorrowStack value={day} onChange={onDayChange} />
        </Box>
      )}

      <PenLotToolbar penLots={penLots} value={selectedPenLotId} onChange={setSelectedPenLotId} />

      {!haveTomorrow && day === 'tomorrow' ? (
        <DuplicateDropsEmptyView
          isFullScreen={false}
          fromDate={formatDateForDB(_today)}
          toDate={formatDateForDB(_tomorrow)}
          onSuccess={didDuplicate}
        />
      ) : (
        <Grid
          container
          px={isPhoneSize ? 1 : 2}
          py={2}
          pb={2}
          columnSpacing={2}
          sx={{ background: (theme) => (day === 'today' ? theme.palette.grey[100] : theme.palette.blue[50]) }}
        >
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              borderRight: (theme) => ({ xs: 'none', md: `1px solid ${theme.palette.grey[400]}` }),
              paddingBottom: { xs: 2, md: 0 },
            }}
          >
            {day == 'today' && (
              <ObservationsBox bunkScore={state.observations.bunkScore} sx={{ mx: { xs: 0, md: 1 } }} />
            )}
            <Box sx={{ mt: 2 }}>
              <FeedPlanBox day={day} currentDay={currentDay} increments={increments} />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box maxHeight={{ xs: 'auto', md: 260 }} overflow="auto">
              <TableContainer>
                <Table sx={{ tableLayout: { xs: 'fixed', md: 'auto' } }}>
                  <TableBody>
                    {day === 'today' && (
                      <StyledTableRow sx={{ display: { xs: 'none', md: 'table-row' } }}>
                        <StyledTableCellHeader>Drop</StyledTableCellHeader>
                        <StyledTableCellHeader>%</StyledTableCellHeader>
                        <StyledTableCellHeader sx={{ width: { xs: 1, md: 'auto' } }}>Ration</StyledTableCellHeader>
                        <StyledTableCellHeader sx={{ minWidth: 200 }}>Called</StyledTableCellHeader>
                        <StyledTableCellHeader>Fed</StyledTableCellHeader>
                        <StyledTableCellHeader sx={{ textAlign: 'right' }}>
                          {dayName[dayjs(today()).add(1, 'day').day()]}
                        </StyledTableCellHeader>
                      </StyledTableRow>
                    )}
                    {day === 'tomorrow' && (
                      <StyledTableRow sx={{ display: { xs: 'none', md: 'table-row' } }}>
                        <StyledTableCellHeader>Drop</StyledTableCellHeader>
                        <StyledTableCellHeader>%</StyledTableCellHeader>
                        <StyledTableCellHeader>{dayName[dayjs(today()).day()]}</StyledTableCellHeader>
                        <StyledTableCellHeader>Fed</StyledTableCellHeader>
                        <StyledTableCellHeader>Ration</StyledTableCellHeader>
                        <StyledTableCellHeader sx={{ minWidth: 200 }}>
                          {dayName[dayjs(today()).add(1, 'day').day()]}
                        </StyledTableCellHeader>
                        <StyledTableCell />
                      </StyledTableRow>
                    )}

                    {day === 'today' && (
                      <StyledTableRow sx={{ background: (theme) => theme.palette.grey[300] }}>
                        <StyledTableCell
                          sx={{
                            width: { xs: '20%', md: 'auto' },
                            display: { xs: 'flex', md: 'table-cell' },
                            alignItems: 'center',
                            fontWeight: 'bold',
                          }}
                        >
                          Total
                        </StyledTableCell>
                        <StyledTableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>100%</StyledTableCell>
                        <StyledTableCell sx={{ width: { xs: '40%', md: 'auto' } }}>
                          <RationInput rations={rations} {...currentDay.rationId} data-testid="drops-total-ration" />
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: { xs: '40%', md: 'auto' } }}>
                          <IncrementalNumberInput
                            data-testid="drops-total-called"
                            increment={increments.af}
                            {...currentDay.afLbs}
                            decimals={0}
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={{ display: { xs: 'none', md: 'table-cell' } }} />
                        <StyledTableCell sx={{ display: { xs: 'none', md: 'table-cell' } }} />
                      </StyledTableRow>
                    )}

                    {day === 'tomorrow' && (
                      <StyledTableRow sx={{ background: (theme) => theme.palette.blue[100] }}>
                        <StyledTableCell
                          sx={{
                            width: { xs: '20%', md: 'auto' },
                            display: { xs: 'flex', md: 'table-cell' },
                            alignItems: 'center',
                            fontWeight: 'bold',
                          }}
                        >
                          Total
                        </StyledTableCell>
                        <StyledTableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>100%</StyledTableCell>
                        <StyledTableCell sx={{ display: { xs: 'none', md: 'table-cell' } }} />
                        <StyledTableCell sx={{ display: { xs: 'none', md: 'table-cell' } }} />
                        <StyledTableCell sx={{ width: { xs: '40%', md: 'auto' } }}>
                          <RationInput rations={rations} {...currentDay.rationId} data-testid="drops-total-ration" />
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: { xs: '40%', md: 'auto' } }}>
                          <IncrementalNumberInput
                            data-testid="drops-total-called"
                            increment={increments.af}
                            {...currentDay.afLbs}
                            decimals={0}
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={{ display: { xs: 'none', md: 'table-cell' } }} />
                      </StyledTableRow>
                    )}

                    {Array.from({ length: dropCount }).map((_, i) => {
                      // using .at(n) here as a hint to typescript that i can be out of bounds here
                      const drop = currentDay.drops.at(i);
                      const todayDrop = state.today.drops.at(i);
                      const tomorrowDrop = state.tomorrow.drops.at(i);
                      return (
                        <>
                          {day === 'today' && (
                            <StyledTableRow key={i} sx={{ background: { xs: 'white', md: 'inherit' } }}>
                              <StyledTableCell
                                sx={{
                                  whiteSpace: 'nowrap',
                                  fontWeight: { xs: 'bold', md: 'normal' },
                                  display: { xs: 'flex', md: 'table-cell' },
                                  alignItems: 'center',
                                  gap: 0.5,
                                  width: { xs: '100%', md: 'auto' },
                                  justifyContent: { xs: 'space-between', md: 'center' },
                                }}
                              >
                                <Typography display="inline">
                                  <Typography fontWeight="bold" display={{ xs: 'inline', md: 'none' }}>
                                    Drop
                                  </Typography>{' '}
                                  {i + 1}
                                </Typography>
                                {drop?.onDelete && <DropDeleteButton onDelete={drop.onDelete} index={i} />}
                              </StyledTableCell>
                              <StyledTableCell
                                data-testid="drop-percent"
                                sx={{
                                  width: { xs: '20%', md: 'auto' },
                                  textAlign: { xs: 'left', md: 'center' },
                                  display: { xs: 'flex', md: 'table-cell' },
                                  alignItems: 'center',
                                }}
                              >
                                {!drop ? (
                                  '-'
                                ) : drop.distributionPercent === 'one-time' ? (
                                  <em>(once)</em>
                                ) : state.distributionPercents ? (
                                  `${state.distributionPercents.value[i]?.toFixed(0) ?? '-'}%`
                                ) : typeof drop.distributionPercent === 'number' ? (
                                  `${drop.distributionPercent.toFixed(0)}%`
                                ) : null}
                              </StyledTableCell>
                              <StyledTableCell sx={{ width: { xs: '40%', md: 'auto' } }}>
                                {!drop ? (
                                  '-'
                                ) : (
                                  <RationInput rations={rations} {...drop.rationId} data-testid="drop-ration" />
                                )}
                              </StyledTableCell>
                              <StyledTableCell sx={{ width: { xs: '40%', md: 'auto' } }}>
                                {!todayDrop ? (
                                  '-'
                                ) : (
                                  <CallAmountInput
                                    increment={increments.af}
                                    {...todayDrop.lbsCalled}
                                    inputProps={{ 'data-testid': 'drop-called' }}
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ display: { xs: 'none', md: 'table-cell' }, whiteSpace: 'nowrap' }}
                                data-testid="drop-fed"
                              >
                                {!todayDrop ? '-' : todayDrop.lbsFed === 0 ? <em>Not Fed</em> : `${todayDrop.lbsFed}`}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ display: { xs: 'none', md: 'table-cell' } }}
                                data-testid="drop-tomorrow"
                              >
                                {!tomorrowDrop ? (
                                  '-'
                                ) : (
                                  <ReadonlyCallWithRation
                                    value={tomorrowDrop.lbsCalled.value.afLbs}
                                    color={rations.find((r) => r.id === tomorrowDrop.rationId.value)?.color}
                                  />
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          )}

                          {day === 'tomorrow' && (
                            <StyledTableRow key={i} sx={{ background: { xs: '#f7fbff', md: 'inherit' } }}>
                              <StyledTableCell
                                sx={{
                                  whiteSpace: 'nowrap',
                                  fontWeight: { xs: 'bold', md: 'normal' },
                                  display: { xs: 'flex', md: 'table-cell' },
                                  alignItems: 'center',
                                  gap: 0.5,
                                  width: { xs: '100%', md: 'auto' },
                                  justifyContent: { xs: 'space-between', md: 'center' },
                                }}
                              >
                                <Typography display="inline">
                                  <Typography fontWeight="bold" display={{ xs: 'inline', md: 'none' }}>
                                    Drop
                                  </Typography>{' '}
                                  {i + 1}
                                </Typography>
                                {drop?.onDelete && <DropDeleteButton onDelete={drop.onDelete} index={i} />}
                              </StyledTableCell>
                              <StyledTableCell
                                data-testid="drop-percent"
                                sx={{
                                  width: { xs: '20%', md: 'auto' },
                                  textAlign: { xs: 'left', md: 'center' },
                                  display: { xs: 'flex', md: 'table-cell' },
                                  alignItems: 'center',
                                }}
                              >
                                {!drop ? (
                                  '-'
                                ) : drop.distributionPercent === 'one-time' ? (
                                  <em>(once)</em>
                                ) : state.distributionPercents ? (
                                  `${state.distributionPercents.value[i]?.toFixed(0) ?? '-'}%`
                                ) : typeof drop.distributionPercent === 'number' ? (
                                  `${drop.distributionPercent.toFixed(0)}%`
                                ) : null}
                              </StyledTableCell>
                              <StyledTableCell
                                data-testid="drop-today"
                                sx={{ display: { xs: 'none', md: 'table-cell' } }}
                              >
                                {!todayDrop ? (
                                  '-'
                                ) : (
                                  <ReadonlyCallWithRation
                                    value={todayDrop.lbsCalled.value.afLbs}
                                    color={rations.find((r) => r.id === todayDrop.rationId.value)?.color}
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ display: { xs: 'none', md: 'table-cell' } }}
                                data-testid="drop-fed"
                              >
                                {!todayDrop ? '-' : todayDrop.lbsFed === 0 ? <em>Not Fed</em> : `${todayDrop.lbsFed}`}
                              </StyledTableCell>

                              <StyledTableCell sx={{ width: { xs: '40%', md: 'auto' } }}>
                                {!drop ? (
                                  '-'
                                ) : (
                                  <RationInput rations={rations} {...drop.rationId} data-testid="drop-ration" />
                                )}
                              </StyledTableCell>

                              <StyledTableCell sx={{ width: { xs: '40%', md: 'auto' } }}>
                                {!tomorrowDrop ? (
                                  '-'
                                ) : (
                                  <CallAmountInput
                                    increment={increments.af}
                                    {...tomorrowDrop.lbsCalled}
                                    inputProps={{ 'data-testid': 'drop-called' }}
                                  />
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </>
                      );
                    })}

                    {currentDay.oneTimeDrops.map((oneTimeDrop, i) => (
                      <StyledTableRow key={i} sx={{ background: { xs: 'white', md: 'inherit' } }}>
                        <StyledTableCell>
                          {oneTimeDrop.onDelete ? (
                            <IconButton
                              disabled={oneTimeDrop.onDelete === undefined}
                              onClick={() => oneTimeDrop.onDelete?.(i)}
                              data-testid="drop-delete-button"
                            >
                              <DeleteIcon
                                color={oneTimeDrop.onDelete === undefined ? 'disabled' : 'primary'}
                                sx={{ fontSize: 16 }}
                              />
                            </IconButton>
                          ) : null}
                        </StyledTableCell>
                        <StyledTableCell />
                        <StyledTableCell>
                          <RationInput rations={rations} {...oneTimeDrop.rationId} data-testid="drop-ration" />
                        </StyledTableCell>
                        <StyledTableCell>
                          <IncrementalNumberInput
                            data-testid="drop-called"
                            increment={increments.af}
                            {...oneTimeDrop.lbsCalled}
                            decimals={0}
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={{ display: { xs: 'none', md: 'table-cell' } }} data-testid="drop-fed">
                          {oneTimeDrop.lbsFed === 0 ? <em>Not Fed</em> : `${oneTimeDrop.lbsFed}`}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Grid container>
              <Grid item xs={6}>
                {currentDay.onAddOneTimeDrop ? (
                  <AddDropButton
                    onAddDrop={currentDay.onAddOneTimeDrop}
                    rations={rations}
                    data-testid="add-one-time-drop-button"
                  >
                    + One Time Drop
                  </AddDropButton>
                ) : null}
              </Grid>
              <Grid item xs={6} textAlign="right">
                {currentDay.onAddRecurringDrop ? (
                  <AddDropButton
                    onAddDrop={currentDay.onAddRecurringDrop}
                    rations={rations}
                    data-testid="add-recurring-drop-button"
                  >
                    + Recurring Drop
                  </AddDropButton>
                ) : null}
                {state.distributionPercents && (
                  <Button
                    data-testid="edit-drop-distribution-button"
                    endIcon={<LaunchIcon />}
                    variant="text"
                    onClick={() => setShowEditDropDistributionModal(true)}
                  >
                    Edit Drop Distribution
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: 8 }} display="none">
            <pre>{JSON.stringify({ dirty, saving })}</pre>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
              <pre>Working Plan: {JSON.stringify(penData, null, 2)}</pre>
              <pre>Actuals: {JSON.stringify(actual, null, 2)}</pre>
              <pre>UI State: {JSON.stringify(state, null, 2)}</pre>
            </div>
          </Grid>
        </Grid>
      )}

      <PenHistoryBox penId={penId} lotId={selectedPenLot?.lotId!} />

      <Dialog open={showEditDropDistributionModal} maxWidth="sm" fullWidth>
        <Box sx={{ padding: 4 }}>
          <DialogTitle title="Daily Drop Distribution" />
          <DistributionEditor
            distributionPercents={state.distributionPercents!}
            onClose={() => setShowEditDropDistributionModal(false)}
            onResetOverrides={currentDay.onResetOverrides}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

const showSaveFailedModal = (message: string): Promise<'cancel' | 'undo-and-retry'> => {
  return new Promise((resolve) => {
    ModalManager.show(SaveFailedModal, { message, onResponse: resolve });
  });
};
const SaveFailedModal = ModalManager.create<{
  message: string;
  onResponse: (response: 'cancel' | 'undo-and-retry') => void;
}>(({ message, onResponse }) => {
  const modal = useModal();
  const isPhoneSize = useIsPhoneSize();

  const onClose: ModalProps['onClose'] = (event, reason) => {
    onResponse('cancel');
    modal.remove();
  };

  return (
    <Dialog onClose={onClose} open={modal.visible} maxWidth={'xs'} fullWidth fullScreen={isPhoneSize}>
      <DialogContainer>
        <DialogContent sx={{ py: 2 }}>
          <Error sx={{ fontSize: 40, mb: 1, color: (theme) => theme.palette.error.main }} />
          <Typography variant="h2" sx={{ pb: 1 }}>
            Save Failed
          </Typography>
          <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            data-test-id="call-plan-save-failed-cancel-button"
            variant="text"
            type="reset"
            onClick={() => {
              onResponse('cancel');
              modal.remove();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            data-test-id="call-plan-save-failed-reload-and-try-again-button"
            onClick={() => {
              onResponse('undo-and-retry');
              modal.remove();
            }}
          >
            Reload and Try Again
          </LoadingButton>
        </DialogActions>
      </DialogContainer>
    </Dialog>
  );
});

const AddDropButton: React.FC<
  PropsWithChildren<{
    onAddDrop: (rationId: number) => void;
    rations: readonly { id: number; name: string; color: string }[];
    'data-testid'?: string;
  }>
> = ({ onAddDrop, rations, children, 'data-testid': dataTestId }) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement>();
  return (
    <>
      <Button variant="text" onClick={(e) => setMenuAnchor(e.currentTarget)} data-testid={dataTestId}>
        {children}
      </Button>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(undefined)}
        data-testid={`${dataTestId}-menu`}
      >
        {rations.map(({ id, name, color }) => (
          <MenuItem
            data-testid={`${dataTestId}-menu-item`}
            key={id}
            value={id}
            onClick={() => {
              onAddDrop(id);
              setMenuAnchor(undefined);
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
              <div style={{ display: 'block', width: '0.8em', height: '0.8em', backgroundColor: color }} />
              <div>{name}</div>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const ReadonlyCallWithRation: React.FC<{ value: number; color: string | undefined }> = ({ value, color }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
      <div
        style={{
          display: 'block',
          width: '0.8em',
          height: '0.8em',
          backgroundColor: color ?? 'transparent',
        }}
      />
      <div>{value.toFixed(0)}</div>
    </div>
  );
};

const confirmSave = (saveFunc: () => Promise<SaveResult>): Promise<boolean> => {
  return new Promise((resolve) => {
    showYouMustSaveFirstDialog({ save: saveFunc, onFinish: (canceled) => resolve(!canceled) });
  });
};
type YouMustSaveFirstDialogProps = {
  save: () => Promise<SaveResult>;
  onFinish: (canceled: boolean) => void;
};
export const showYouMustSaveFirstDialog = (props: YouMustSaveFirstDialogProps) =>
  ModalManager.show(YouMustSaveFirstDialog, props);
const YouMustSaveFirstDialog = ModalManager.create<YouMustSaveFirstDialogProps>(({ save, onFinish }) => {
  const modal = useModal();
  const [saving, setSaving] = useState(false);
  const isPhoneSize = useIsPhoneSize();

  const doSave = useCallback(() => {
    (async () => {
      setSaving(true);
      try {
        // TODO: handle save failure
        const result = await save();
        setSaving(false);
        onFinish(result !== 'success');
        modal.remove();
      } catch (e) {
        setSaving(false);
        console.error(e);
      }
    })();
  }, [modal, save, onFinish]);

  const doCancel = useCallback(() => {
    modal.remove();
    onFinish(true);
  }, [modal, onFinish]);

  return (
    <Dialog onClose={doCancel} open={modal.visible} maxWidth={'xs'} fullWidth fullScreen={isPhoneSize}>
      <DialogContainer>
        <DialogContent sx={{ py: 2 }}>
          <Error sx={{ fontSize: 40, mb: 1, color: (theme) => theme.palette.yellow[400] }} />
          <Typography variant="h2" sx={{ pb: 1 }}>
            {'Save Required'}
          </Typography>
          <Typography>{'Going to tomorrow will save your changes for today.'}</Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <LoadingButton data-testid="call-plan-save-first-save-and-continue-button" onClick={doSave} loading={saving}>
            {'Save and Continue'}
          </LoadingButton>
          <Button data-testid="call-plan-save-first-cancel-button" variant="text" type="reset" onClick={doCancel}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContainer>
    </Dialog>
  );
});

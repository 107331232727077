import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';

export const FlashMessageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.yellow[100],
  padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
  borderRadius: theme.spacing(1),
}));

export const FlashMessageInfoIcon = styled(InfoIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));
FlashMessageInfoIcon.defaultProps = {
  fontSize: 'small',
};

export const UnsavedFlashMessage = () => {
  const isPhoneSize = useIsPhoneSize();
  return (
    <FlashMessageContainer>
      {!isPhoneSize && <FlashMessageInfoIcon />}
      <span data-testid="call-feed-tab-unsaved-changes-text">You have unsaved changes.</span>
    </FlashMessageContainer>
  );
};

import { isEqual } from 'lodash';
import {
  Day,
  Empty,
  PenCallByDistributionPlanDay,
  PenCallByDistributionPlan,
  PenCallPlanFunctions,
  toPlanAmount,
} from '.';
import {
  calcCallsForPerDistributionPlan,
  solveAmountAndDistributionForSpecifiedDropAmount,
} from './byDistributionFunctions';
import { normalizeDistribution } from './functions';
import { planUnitConvert } from '@/common/callFeed/planCallUnit';
import { PlanCallUnit } from '@/web-types';

type T = PenCallPlanFunctions<PenCallByDistributionPlan>;
export const resolveDay = (plan: PenCallByDistributionPlan, day: Day): PenCallByDistributionPlanDay =>
  day === 'today'
    ? {
        method: plan.method,
        distribution: applyOverride(plan.distributionTodayOverride, plan.distribution),
        callAmount: plan.callAmount,
        callUnit: plan.callUnit,
        rationIds: plan.rationIds,
        oneTimeDrops: plan.oneTimeDropsToday ?? Empty,
      }
    : {
        method: plan.method,
        distribution: applyOverride(plan.distributionTomorrowOverride, plan.distribution),
        callAmount: plan.callAmountAfterToday ?? plan.callAmount,
        callUnit: plan.callUnit,
        rationIds: plan.rationIdsAfterToday ?? plan.rationIds,
        oneTimeDrops: Empty,
      };

export const calcCalls: T['calcCalls'] = (plan, state, day) => {
  const planDay = resolveDay(plan, day);
  return calcCallsForPerDistributionPlan({
    planDistribution: planDay.distribution,
    planPenAmount: planDay.callAmount,
    planUnit: planDay.callUnit,
    planRationIds: planDay.rationIds,
    currentHead: state.currentHead,
    rationIdToDmRatio: state.rationIdToDmRatio,
    drops: state.dropsMap[day],
    adjustLastForActualFed: plan.adjustLastForActualFed,
  });
};

// Today's override must be padded with zeros to match or exceed the main distribution's length.
// Reasons:
// 1. The main distribution is stored as the plan amount on the drops in the database. If today's override
//    only has 2 drops but the main needs to store 3 drops, you need a third drop in the database for this.
//    (This restriction might be removed later, for instance if we start using a plan table for storage.)
// 2. Overriding today's distribution would irreversibly lock in the number of recurring drops for today.
//    For example, if you override today when the main distribution has only 2 drops, then there would be no way
//    to increase that to 3 because even if you increased the main distribution to 3, today's override only has 2.
//    This is mitigated by padding overrides with zeros.
//
const applyOverride = (override: readonly number[] | undefined, main: readonly number[]) => {
  if (!override) return main;
  if (override.length >= main.length) return override;
  return main.map((_, i) => override[i] ?? 0);
};

export const resetOverrides: T['resetOverrides'] = (plan, day) => {
  return {
    ...plan,
    [day === 'today' ? 'distributionTodayOverride' : 'distributionTomorrowOverride']: undefined,
  };
};

export const setDistributionPercents: T['setDistributionPercents'] = (plan, value, defaultRationId) => {
  const distribution = normalizeDistribution(value.map((d, i) => d / 100));
  const rationIds = plan.rationIds.length === 0 ? distribution.map((_) => defaultRationId) : plan.rationIds;
  const rationIdsAfterToday =
    plan.rationIdsAfterToday?.length === 0 ? distribution.map((_) => defaultRationId) : plan.rationIdsAfterToday;
  return { ...plan, distribution, rationIds, rationIdsAfterToday };
};

export const setDmiPerHead: T['setDmiPerHead'] = (plan, _, day, dmiLbsPerHead) => {
  if (plan.callUnit !== PlanCallUnit.DmiLbsPerHead) {
    throw new Error('Not implemented: set dmi per head of non-dmi-per-head plan');
  }
  return {
    ...plan,
    [day === 'today' ? 'callAmount' : 'callAmountAfterToday']: dmiLbsPerHead,
  };
};

export const setAfPerHead: T['setAfPerHead'] = (plan, _, day, afLbsPerHead) => {
  if (plan.callUnit !== PlanCallUnit.AsFedLbsPerHead) {
    throw new Error('Not implemented: set as fed per head of non-as-fed-per-head plan');
  }
  return {
    ...plan,
    [day === 'today' ? 'callAmount' : 'callAmountAfterToday']: afLbsPerHead,
  };
};
export const setAfTotal: T['setAfTotal'] = () => {
  throw new Error('Not implemented');
};

export const setDropAmount: T['setDropAmount'] = (plan, state, day, dropIndex, dropAmount) => {
  const { rationIdToDmRatio } = state;
  const dayPlan = resolveDay(plan, day);
  const dropDmiPerHead = toPlanAmount(dropAmount);
  const actualMap = state.dropsMap[day]
    .filter((d) => !d.oneTime) // one time drops are not included when calculating distributions
    .map(
      (d) =>
        d.actual &&
        planUnitConvert(
          d.actual.afLbsCalled,
          PlanCallUnit.AsFedLbs,
          plan.callUnit,
          d.actual.head,
          rationIdToDmRatio(d.actual.rationId)
        )
    );

  const solved = solveAmountAndDistributionForSpecifiedDropAmount(
    dayPlan.callAmount, // planAmount
    dayPlan.distribution, // planDistribution
    dropIndex, // dropIndex
    dropDmiPerHead, // dropAmount
    actualMap // actualAmounts
  );

  return {
    ...plan,
    [day === 'today' ? 'distributionTodayOverride' : 'distributionTomorrowOverride']:
      solved.distribution !== plan.distribution && !isEqual(solved.distribution, plan.distribution)
        ? solved.distribution
        : undefined,
    [day === 'today' ? 'callAmount' : 'callAmountAfterToday']: solved.amount,
  };
};

export const deleteRecurringDrop: T['deleteRecurringDrop'] = (plan, day, dropIndex) => {
  throw new Error('Not implemented for by-distribution');
};

export const addRecurringDrop: T['addRecurringDrop'] = (plan, day, rationId) => {
  throw new Error('Not implemented for by-distribution');
};

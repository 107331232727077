import { Box, FormGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { FormProvider, useForm } from 'react-hook-form';
import ControlledSelectField from '@/components/ControlledSelectField';
import { ChipStack } from '@/components/ChipStack';
import { gql } from 'graphql-tag';
import { BunkScoreValue, useUpdateBunkScoresMutation } from '@/web-types';
import { enqueueSnackbar } from 'notistack';
import { formatLbs, formatNumber } from '@/components/helpers/format';
import { LoadingButton } from '@mui/lab';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { bunkScoreOptions } from './constants';
import { bunkScoreLabel } from '@/common/dictionaries/bunkScore';
import { CHANGES_SAVED_MESSAGE } from '@/common/messages';

gql`
  mutation UpdateBunkScores($bunkScores: [InputUpdateBunkScore!]!) {
    updateBunkScores(bunkScores: $bunkScores)
  }
`;

export type BunkScoringTabProps = {
  onChange?: () => void;
  pens: {
    id: number;
    name?: string | null;
    numHeads?: number | null;
    rations?:
      | null
      | {
          name?: string;
        }[];
    bunkScoring?: null | {
      fedLbsYesterday: number;
      calledLbsYesterday: number;
      varianceYesterday: number;
    };
    bunkScoreToday?: null | { bunkScore?: BunkScoreValue | null };
    bunkScoreYesterday?: null | { bunkScore?: BunkScoreValue | null };
  }[];
};

export interface BunkScoringFormType {
  bunkScores: string[];
}

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));

const emptyValue = '-';

export default function BunkScoringTab({ pens, onChange }: BunkScoringTabProps) {
  const [{ fetching }, updateBunkScores] = useUpdateBunkScoresMutation();
  const isPhoneSize = useIsPhoneSize();

  const methods = useForm<BunkScoringFormType>({
    values: {
      bunkScores: pens?.map((p) => p.bunkScoreToday?.bunkScore ?? emptyValue),
    },
  });
  const { control, reset, getValues, formState } = methods;
  const values = getValues();

  const saveChanges = async () => {
    const { error } = await updateBunkScores({
      bunkScores: values.bunkScores.map((b, index) => ({
        penId: pens[index].id,
        bunkScore: b === emptyValue ? null : (b as BunkScoreValue),
      })),
    });
    if (error) return;

    onChange?.();

    enqueueSnackbar(CHANGES_SAVED_MESSAGE, { variant: 'success' });
  };

  return (
    <FormProvider {...methods}>
      <FormGroup>
        <Container>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <LoadingButton variant="outlined" disabled={!formState.isDirty || fetching} onClick={() => reset()}>
              Cancel
            </LoadingButton>
            <LoadingButton
              data-testid="bunk-scoring-tab-save-button"
              disabled={!formState.isDirty || fetching}
              loading={fetching}
              onClick={saveChanges}
            >
              Save Changes
            </LoadingButton>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Pen</TableCell>
                  {!isPhoneSize && (
                    <>
                      <TableCell>Ration(s)</TableCell>
                      <TableCell>Head</TableCell>
                      <TableCell>YDAY Score</TableCell>
                      <TableCell>YDAY Call</TableCell>
                      <TableCell>YDAY Fed</TableCell>
                      <TableCell>Variance</TableCell>
                    </>
                  )}
                  <TableCell>Bunk Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pens.map((pen, index) => {
                  return (
                    <TableRow hover key={pen.id}>
                      <TableCell component="th" scope="row">
                        {pen.name}
                      </TableCell>
                      {!isPhoneSize && (
                        <>
                          <TableCell>
                            <ChipStack
                              labels={pen.rations?.map((ration) => ration.name ?? '') ?? []}
                              maxLength={3}
                              color="purple"
                            />
                          </TableCell>

                          <TableCell>{formatNumber(pen.numHeads)}</TableCell>
                          <TableCell>
                            {pen.bunkScoreYesterday?.bunkScore && bunkScoreLabel(pen.bunkScoreYesterday?.bunkScore)}
                          </TableCell>
                          <TableCell>{formatLbs(pen.bunkScoring?.calledLbsYesterday)}</TableCell>
                          <TableCell>{formatLbs(pen.bunkScoring?.fedLbsYesterday)}</TableCell>
                          <TableCell>{formatLbs(pen.bunkScoring?.varianceYesterday, true, true)}</TableCell>
                        </>
                      )}
                      <TableCell>
                        <ControlledSelectField
                          name={`bunkScores.${index}`}
                          control={control}
                          data-testid={`bunk-scoring-tab-bunk-score-select-${index}`}
                        >
                          <MenuItem value="-">
                            <em>-</em>
                          </MenuItem>

                          {bunkScoreOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              data-testid={`bunk-scoring-tab-bunk-score-select-value-${index}-${option.value}`}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </ControlledSelectField>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </FormGroup>
    </FormProvider>
  );
}

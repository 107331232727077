import { Button, styled } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import dayjs from 'dayjs';

const StyledButton = styled(Button)({
  px: 1,
  py: 0,
  height: 30,
  fontWeight: 400,
  fontSize: 12,
  boxShadow: 'none',
  borderRadius: 5,
  border: '1px solid #B3C3CE !important',
  '&:hover': { background: '#B3C3CE !important', boxShadow: 'none !important' },
});

export const TodayTomorrowStack = ({
  value,
  onChange,
  'data-testid': testid,
}: {
  value: 'today' | 'tomorrow';
  onChange: (value: 'today' | 'tomorrow') => void;
  'data-testid'?: string;
}) => {
  const testIdPrefix = testid ? `${testid}-` : '';
  const today = dayjs.tz();
  const tomorrow = today.add(1, 'day');

  return (
    <ButtonGroup
      sx={{ width: { xs: '100%', sm: 'fit-content' }, display: { xs: 'flex', md: 'block' }, button: { flex: 1 } }}
    >
      <StyledButton
        color="inherit"
        variant={value === 'today' ? 'contained' : 'outlined'}
        onClick={() => onChange('today')}
        data-testid={`${testIdPrefix}today-button`}
        title={`Today ${today.format('ddd, D')}`}
      >
        Today
      </StyledButton>
      <StyledButton
        color="inherit"
        variant={value === 'tomorrow' ? 'contained' : 'outlined'}
        onClick={() => onChange('tomorrow')}
        data-testid={`${testIdPrefix}tomorrow-button`}
        title={`Tomorrow ${tomorrow.format('ddd, D')}`}
      >
        Tomorrow
      </StyledButton>
    </ButtonGroup>
  );
};

import { useCallback, useState } from 'react';
import { NumberInput } from './NumberInput';
import { Button, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Input } from '@/components/types';
import { normalizeDistribution, redistributeAroundForced } from '@/components/CallFeed/callPlan/functions';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';

export const PercentsInput: React.FC<Input<readonly number[]>> = ({ value, onChange }) => {
  const [indexHistory, setIndexHistory] = useState<readonly number[]>([]);
  const onChangeSingle = useCallback(
    (percent: number, params: unknown | undefined) => {
      const newVal = Math.max(0, Math.min(100, percent)) / 100;
      const index = Number(params);
      const next = value.map((v) => v / 100).with(index, newVal);
      setIndexHistory((prev) => [index, ...prev.filter((v) => v < value.length && v !== index)]);
      const preserve = [index, ...indexHistory.filter((v) => v < value.length && v !== index)];
      while (preserve.length > 0) {
        const redistributed = redistributeAroundForced(
          normalizeDistribution(next),
          next.map((v, i) => (preserve.includes(i) ? v : undefined))
        );
        if (redistributed.length === next.length) {
          onChange(
            redistributed.map((v) => v * 100),
            undefined
          );
          break;
        }
        preserve.pop();
      }
    },
    [indexHistory, onChange, value]
  );

  return (
    <Box>
      <Grid container gap={1} alignItems="center" fontWeight="bold">
        <Grid item xs={3} textAlign="center">
          Drops
        </Grid>
        <Grid item xs={6} textAlign="center">
          Percent of Total Fed
        </Grid>
      </Grid>
      {value.map((v, i) => (
        <Grid container gap={1} key={i} alignItems="center" py={1}>
          <Grid item xs={3} textAlign="center">
            {i + 1}
          </Grid>
          <Grid item xs={6}>
            <NumberInput
              data-testid="distribution-percent-input"
              value={v}
              onChange={onChangeSingle}
              params={i}
              decimals={0}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={() => {
                const newVal = [...value.slice(0, i), ...value.slice(i + 1)];
                onChange(
                  normalizeDistribution(newVal).map((v) => v * 100),
                  undefined
                );
              }}
              data-testid="distribution-delete-drop-button"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        data-testid="distribution-add-drop-button"
        variant="text"
        onClick={() => onChange(value.length ? [...value, 0] : [100])}
      >
        + Add Drop
      </Button>
    </Box>
  );
};

import { BunkScoreValue, FeedingMethod, PlanCallMethod, PlanCallUnit } from '@/web-types';
import { useCallback, useMemo } from 'react';
import { RationToDryMatterRatio } from '@/common/models/ration';
import { sumBy } from 'lodash';
import { normalizeDistribution } from '../callPlan/functions';
import {
  addOneTimeDrop,
  addRecurringDrop,
  calcCalls,
  CallAmount,
  CallFeedDropIdAndActual,
  CallFeedDropsMap,
  Day,
  deleteOneTimeDrop,
  deleteRecurringDrop,
  Empty,
  PenCallPlan,
  PenCallPlanDay,
  PenState,
  resetOverrides,
  resolveDay,
  setAfPerHead,
  setAfTotal,
  setDistributionPercents,
  setDmiPerHead,
  setDropAmount,
  setDropRation,
  setOneTimeDropAmount,
  setOneTimeDropRation,
  setPenRation,
} from '../callPlan';
import { assertImpossible } from '@/common/utils/assertImpossible';
import { PenObservations, PlanAndObservations } from './useCallFeedData';
import { Input } from '@/components/types';

/**
 * This function returns a UI state which contains values and modifiers for those values
 * This hook requires an input structure {@link CallFeedUiStateArgs} which contains
 *   1. the current working data (the data the user can change), and
 *   2. a function to set that data
 *   3. external data such as head, drops map, weight
 * It returns a structure {@link CallFeedUiState} which contains
 *   1. the values to display in the UI
 *   2. modifiers where appropriate for the UI to change those values or perform actions
 * This function has two purposes:
 *   1. Map the values from the input structures to the output structure which is in the shape of the UI
 *   2. Create modifiers for the different UI events which all call the provided {@link CallFeedUiStateArgs.setData} function with a new data structure that has had the modification made
 */
export function useCallFeedUiState({
  yesterdayNumHead,
  currentHead,
  rationIdToDmRatio,
  planYesterday,
  dropsMapYesterday,
  data,
  setData,
  dropsMap,
  feedingMethod,
  yesterdayWeightPerHeadLbs,
  currentWeightPerHeadLbs,
  defaultRationId,
}: CallFeedUiStateArgs): CallFeedUiState {
  // construct an object from the parts of the pen state
  const penState: PenState = useMemo(() => {
    console.debug(`recalculate useCallFeedUiState.penState`);
    return { currentHead, rationIdToDmRatio, dropsMap, defaultRationId };
  }, [currentHead, rationIdToDmRatio, dropsMap, defaultRationId]);

  const penStateYesterday: PenState = useMemo(
    () => ({
      currentHead: yesterdayNumHead,
      rationIdToDmRatio,
      dropsMap: { today: dropsMapYesterday, tomorrow: [], duplicationHasOccurred: false },
      defaultRationId,
    }),
    [yesterdayNumHead, rationIdToDmRatio, dropsMapYesterday, defaultRationId]
  );
  // get stable modifier functions
  const modifiers = useCallFeedModifiers(setData, penState);
  // construct the UI state
  const uiState: CallFeedUiState = useMemo(() => {
    console.debug(`recalculate useCallFeedUiState.uiState`);
    const { plan, observationsToday } = data;
    return {
      observations: {
        bunkScore: {
          value: observationsToday.bunkScore,
          onChange: modifiers.forObservations.onChangeBunkScore,
        },
      },
      today: todayStateFromPlanAndActual(
        planYesterday,
        plan,
        penStateYesterday,
        penState,
        modifiers.forPlan.today,
        feedingMethod,
        yesterdayWeightPerHeadLbs,
        currentWeightPerHeadLbs
      ),
      tomorrow: tomorrowStateFromPlan(plan, penState, modifiers.forPlan.tomorrow, currentWeightPerHeadLbs),
      distributionPercents:
        plan.method === PlanCallMethod.ByDrop
          ? undefined
          : {
              value: plan.distribution.map((v) => v * 100),
              onChange: modifiers.forPlan.onChangeDistributionPercents,
            },
    };
  }, [
    data,
    modifiers,
    planYesterday,
    penStateYesterday,
    penState,
    feedingMethod,
    yesterdayWeightPerHeadLbs,
    currentWeightPerHeadLbs,
  ]);

  return uiState;
}

type Modifiers = ReturnType<typeof useCallFeedModifiers>;

// This function provides stable modifiers for call plans
// The modifier functions themselves only change state of the pen changes (head, actuals, rations)

/**
 * The purpose of this hook is to provide stable modifier functions used by the UI.
 * "stable" means that the function references will not change unnecessarily.
 * "modifier functions" are functions that are called by the UI to modify the current working state.
 * The input to this function is a standard setter function which accepts a state transition function in order to update state.
 * A state transition function describes a specific change of state by accepting the previous state and returning the changed state.
 * This hook returns modifiers which call the provided `setData` function with appropriate state transition functions for each modification
 * e.g. the state transition function for changing the amount of a specific drop will take a previous state and return a new state where the amount of the specified drop has been modified
 */
function useCallFeedModifiers(
  /** A standard setter function for modifying the current working state consisting of both plan and observations */
  setData: (fn: (prev: PlanAndObservations) => PlanAndObservations) => void,
  /** Extra non-modifiable data which is referenced during some modifications while modifying the plan and observations */
  penState: PenState
) {
  /** Wrap the setData function with a function that modifies only the `observationsToday` part */
  const setObservations = useCallback(
    (fn: (prev: PenObservations) => PenObservations) =>
      setData((prevData) => ({ ...prevData, observationsToday: fn(prevData.observationsToday) })),
    [setData]
  );
  /** All of the modifiers for observations */
  const forObservations = useMemo(
    () => ({
      onChangeBunkScore: (bunkScore: BunkScoreValue | undefined) =>
        setObservations((prevObservations) => ({ ...prevObservations, bunkScore })),
      onChangeAgressionScore: (agressionScore: number | undefined) =>
        setObservations((prevObservations) => ({ ...prevObservations, agressionScore })),
      onChangeNotes: (notes: string) =>
        setObservations((prevObservations) => ({ ...prevObservations, notes: notes.trim() })),
    }),
    [setObservations]
  );
  /** Update the `plan` part of the working state using a transition function */
  const setPlan = useCallback(
    (fn: (prev: PenCallPlan) => PenCallPlan) => setData((prevData) => ({ ...prevData, plan: fn(prevData.plan) })),
    [setData]
  );
  /** Update the `plan` part of the working state using a transition function and the current pen state */
  const setPlanWithState = useCallback(
    (fn: (prev: PenCallPlan, state: PenState) => PenCallPlan) => setPlan((prev) => fn(prev, penState)),
    [setPlan, penState]
  );
  /** All of the modifiers for plan */
  const forPlan = useMemo(() => {
    return {
      // Each of the following are modifiers for the UI
      // Each calls either `setPlan` or `setPlanWithState` (which ultimately call `setData` to make the change)
      // The `setPlan` functions accept a transition function which takes the previous plan and returns the modified plan

      onChangeDistributionPercents: (value: readonly number[]) =>
        setPlanWithState((prevPlan, state) => setDistributionPercents(prevPlan, value, state.defaultRationId)),

      today: {
        onResetOverrides: () => setPlan((prevPlan) => resetOverrides(prevPlan, 'today')),

        onChangePenRation: (value: number) => setPlan((prevPlan) => setPenRation(prevPlan, 'today', value)),

        onChangeDmiPerHead: (value: number) =>
          setPlanWithState((prevPlan, state) => setDmiPerHead(prevPlan, state, 'today', value)),

        onChangeAfPerHead: (value: number) =>
          setPlanWithState((prevPlan, state) => setAfPerHead(prevPlan, state, 'today', value)),

        onChangeAfTotal: (value: number) =>
          setPlanWithState((prevPlan, state) => setAfTotal(prevPlan, state, 'today', value)),

        onChangeDropRation: (value: number, params: unknown) =>
          setPlan((prevPlan) => setDropRation(prevPlan, 'today', Number(params), value)),

        onChangeDropAmount: (value: CallAmount, params: unknown) =>
          setPlanWithState((prevPlan, state) => setDropAmount(prevPlan, state, 'today', Number(params), value)),

        onChangeOneTimeDropRation: (value: number, params: unknown) =>
          setPlan((prevPlan) => setOneTimeDropRation(prevPlan, Number(params), value)),

        onChangeOneTimeDropAfLbs: (value: number, params: unknown) =>
          setPlan((prevPlan) => setOneTimeDropAmount(prevPlan, Number(params), value)),

        onDeleteOneTimeDrop: (params: unknown) => setPlan((prevPlan) => deleteOneTimeDrop(prevPlan, Number(params))),

        onAddOneTimeDrop: (rationId: number) => setPlan((prevPlan) => addOneTimeDrop(prevPlan, rationId)),

        onDeleteRecurringDrop: (params: unknown) =>
          setPlan((prevPlan) => deleteRecurringDrop(prevPlan, 'today', Number(params))),

        onAddRecurringDrop: (rationId: number) => setPlan((prevPlan) => addRecurringDrop(prevPlan, 'today', rationId)),
      },
      tomorrow: {
        onResetOverrides: () => setPlan((prevPlan) => resetOverrides(prevPlan, 'tomorrow')),

        onChangePenRation: (value: number) => setPlan((prevPlan) => setPenRation(prevPlan, 'tomorrow', value)),

        onChangeDmiPerHead: (value: number) =>
          setPlanWithState((prevPlan, state) => setDmiPerHead(prevPlan, state, 'tomorrow', value)),

        onChangeAfPerHead: (value: number) =>
          setPlanWithState((prevPlan, state) => setAfPerHead(prevPlan, state, 'tomorrow', value)),

        onChangeAfTotal: (value: number) =>
          setPlanWithState((prevPlan, state) => setAfTotal(prevPlan, state, 'tomorrow', value)),

        onChangeDropRation: (value: number, params: unknown) =>
          setPlan((prevPlan) => setDropRation(prevPlan, 'tomorrow', Number(params), value)),

        onChangeDropAmount: (value: CallAmount, params: unknown) =>
          setPlanWithState((prevPlan, state) => setDropAmount(prevPlan, state, 'tomorrow', Number(params), value)),

        onDeleteRecurringDrop: (params: unknown) =>
          setPlan((prevPlan) => deleteRecurringDrop(prevPlan, 'tomorrow', Number(params))),

        onAddRecurringDrop: (rationId: number) =>
          setPlan((prevPlan) => addRecurringDrop(prevPlan, 'tomorrow', rationId)),
      },
    };
  }, [setPlanWithState, setPlan]);
  return { forPlan, forObservations };
}

/** Return the plan but with all the "after today" and "overrides" resolved based on the selected day and removed */
function planForDay(wholePlan: PenCallPlan, day: Day): { plan: PenCallPlanDay; distributionPercents: number[] } {
  const plan = resolveDay(wholePlan, day);
  // distributions work differently in the UI for by-drop and by-distribution
  // for by-drop we just calculate the distribution based on the drops and show it
  // for by-distribution, we always show the configured distribution regardless of whether the drop amounts for that day still match
  const distributionPercents =
    plan.method === PlanCallMethod.ByDrop
      ? wholePlan.method === PlanCallMethod.ByDrop
        ? normalizeDistribution(plan.dropCallAmounts).map((d) => d * 100)
        : // this isn't great, but typescript can't currently infer that wholePlan.method must be equal to plan.method
          assertImpossible('plan.method !== wholePlan.method')
      : plan.distribution.map<number>((d, i) => d * 100);
  return { plan, distributionPercents };
}

/**
 * Returns the pen level amounts given the pen call plan for the day
 * This shows the amount configured directly by the plan (if any) and calculates the rest
 */
function penAmounts(
  plan: PenCallPlanDay,
  calls: ReturnType<typeof calcCalls>,
  rationIdToDmRatio: RationToDryMatterRatio
): { dmiLbsPerHead: number; afLbsPerHead: number; afLbsTotal: number } {
  const dmiLbsPerHead =
    plan.method === PlanCallMethod.ByDistribution && plan.callUnit === PlanCallUnit.DmiLbsPerHead
      ? plan.callAmount
      : sumBy(calls, (c) => (c.amount.afLbs / c.head) * rationIdToDmRatio(c.rationId));
  const afLbsPerHead =
    plan.method === PlanCallMethod.ByDistribution && plan.callUnit === PlanCallUnit.AsFedLbsPerHead
      ? plan.callAmount
      : sumBy(calls, (c) => c.amount.afLbs / c.head);
  const afLbsTotal = sumBy(calls, (c) => c.amount.afLbs);
  return { dmiLbsPerHead, afLbsPerHead, afLbsTotal };
}

/** This function calculates the UI state for the "today" view in the UI */
function todayStateFromPlanAndActual(
  wholePlanYesterday: PenCallPlan,
  wholePlan: PenCallPlan,
  penStateYesterday: PenState,
  penState: PenState,
  modifiers: Modifiers['forPlan']['today'],
  feedingMethod: FeedingMethod,
  yesterdayWeightPerHeadLbs: number | null,
  currentWeightPerHeadLbs: number | null
): CallFeedDayUiState {
  const { plan: planYesterday } = planForDay(wholePlanYesterday, 'today');
  const { plan, distributionPercents } = planForDay(wholePlan, 'today');

  // this is the same regardless of call method
  const rationId = {
    value: wholePenRation(plan.rationIds),
    onChange: modifiers.onChangePenRation,
  };
  const callsYesterday = calcCalls(wholePlanYesterday, penStateYesterday, 'today');
  const calls = calcCalls(wholePlan, penState, 'today');

  const {
    dmiLbsPerHead: dmiLbsPerHeadPreviousDay,
    afLbsPerHead: afLbsPerHeadPreviousDay,
    afLbsTotal: afLbsPreviousDay,
  } = penAmounts(planYesterday, callsYesterday, penStateYesterday.rationIdToDmRatio);
  const { dmiLbsPerHead, afLbsPerHead, afLbsTotal } = penAmounts(plan, calls, penState.rationIdToDmRatio);

  const { dropsMap } = penState;

  // for AfPerDrop, once all drops are loaded or fed, no editing of pen level values is allowed (until we define how that should work)
  const allByDropFed =
    plan.method === PlanCallMethod.ByDrop &&
    calls.every((call) => dropsMap.today.some((a) => a.dropId === call.dropId && a.actual));
  // for now, if there are no drops, then editing pen level values is not allowed
  // in the future, we may enable this and auto create drops
  const noDrops = calls.length === 0;
  const penLevelLocked = allByDropFed || noDrops;

  const dmAsPercentOfBW = currentWeightPerHeadLbs ? dmiLbsPerHead / currentWeightPerHeadLbs : null;
  const dmAsPercentOfBWPreviousDay = yesterdayWeightPerHeadLbs
    ? dmiLbsPerHeadPreviousDay / yesterdayWeightPerHeadLbs
    : null;

  const drops = calls.map<CallFeedRecurringDropUiState>(({ dropId, rationId, amount }, i) => ({
    dropId,
    distributionPercent: distributionPercents[i] ?? 'one-time',
    rationId: {
      params: i,
      value: rationId,
      onChange: modifiers.onChangeDropRation,
      disabled:
        // disabled if this is an auto-added drop (TODO: consider supporting this)
        i >= distributionPercents.length ||
        // disabled if the load is started (targeted) or the drop is fed
        dropsMap.today.some((a) => a.dropId === dropId && (a.isLoadStarted || a.actual)),
    },
    lbsCalled: {
      params: i,
      value: amount,
      onChange: modifiers.onChangeDropAmount,
      disabled:
        // disabled if this is an auto-added drop (TODO: consider supporting this)
        i >= distributionPercents.length ||
        // disabled if the load is either loaded or fed and we're not a batch feeder
        (dropsMap.today.some((a) => a.dropId === dropId && a.actual) &&
          !(feedingMethod === FeedingMethod.Batch && plan.method === PlanCallMethod.ByDrop)),
    },
    lbsFed: dropsMap.today.find((a) => a.dropId === dropId)?.actual?.afLbsFed ?? 0,
    onDelete:
      plan.method === PlanCallMethod.ByDrop &&
      !dropsMap.today.some((a) => a.dropId === dropId && (a.isLoadStarted || a.actual))
        ? modifiers.onDeleteRecurringDrop
        : undefined,
  }));

  return {
    rationId,
    dmiLbsPerHeadPreviousDay,
    dmiLbsPerHead: {
      value: dmiLbsPerHead,
      onChange: modifiers.onChangeDmiPerHead,
      disabled: penLevelLocked || plan.callUnit === PlanCallUnit.AsFedLbsPerHead,
    },
    dmiLbsPerHeadDayDifference: dmiLbsPerHead - dmiLbsPerHeadPreviousDay,
    afLbsPerHeadPreviousDay,
    afLbsPerHead: {
      value: afLbsPerHead,
      onChange: modifiers.onChangeAfPerHead,
      disabled: penLevelLocked || plan.callUnit === PlanCallUnit.DmiLbsPerHead,
    },
    afLbsPerHeadDayDifference: afLbsPerHead - afLbsPerHeadPreviousDay,
    afLbsPreviousDay,
    afLbsDayDifference: afLbsTotal - afLbsPreviousDay,
    afLbs: {
      value: afLbsTotal,
      onChange: modifiers.onChangeAfTotal,
      disabled: penLevelLocked || plan.callUnit !== PlanCallUnit.AsFedLbs,
    },
    dmAsPercentOfBWPreviousDay,
    dmAsPercentOfBW,
    dmAsPercentOfBWDayDifference: (dmAsPercentOfBW ?? 0) - (dmAsPercentOfBWPreviousDay ?? 0),
    fedLbs: sumBy(drops, (d) => d.lbsFed),
    drops,
    oneTimeDrops: plan.oneTimeDrops.map((r, i) => {
      const drop = dropsMap.today.find((a) => a.dropId === r.id);
      const fedLbs = drop?.actual?.afLbsFed ?? 0;
      return {
        id: r.id,
        rationId: {
          params: i,
          value: r.rationId,
          onChange: modifiers.onChangeOneTimeDropRation,
          disabled: fedLbs > 0,
        },
        lbsCalled: {
          params: i,
          value: r.afLbs,
          onChange: modifiers.onChangeOneTimeDropAfLbs,
          disabled: fedLbs > 0,
        },
        lbsFed: dropsMap.today.find((a) => a.dropId === r.id)?.actual?.afLbsFed ?? 0,
        onDelete: drop?.isLoadStarted || fedLbs > 0 ? undefined : modifiers.onDeleteOneTimeDrop,
      };
    }),
    onAddOneTimeDrop: modifiers.onAddOneTimeDrop,
    onAddRecurringDrop: plan.method === PlanCallMethod.ByDrop ? modifiers.onAddRecurringDrop : undefined,
    onResetOverrides:
      wholePlan.method === PlanCallMethod.ByDistribution && wholePlan.distributionTodayOverride
        ? modifiers.onResetOverrides
        : undefined,
  };
}

/** This function calculates the UI state for the "tomorrow" view in the UI */
function tomorrowStateFromPlan(
  wholePlan: PenCallPlan,
  penState: PenState,
  modifiers: Modifiers['forPlan']['tomorrow'],
  currentWeightPerHeadLbs: number | null
): CallFeedDayUiState {
  const { plan, distributionPercents } = planForDay(wholePlan, 'tomorrow');

  // this is the same regardless of call method
  const rationId = {
    value: wholePenRation(plan.rationIds),
    onChange: modifiers.onChangePenRation,
  };
  const calls = calcCalls(wholePlan, penState, 'tomorrow');
  const { dmiLbsPerHead, afLbsPerHead, afLbsTotal } = penAmounts(plan, calls, penState.rationIdToDmRatio);

  // for now, if there are no drops, then editing pen level values is not allowed
  // in the future, we may enable this and auto create drops
  const noDrops = calls.length === 0;
  const penLevelLocked = noDrops;

  const dmAsPercentOfBW = currentWeightPerHeadLbs ? dmiLbsPerHead / currentWeightPerHeadLbs : null;

  const dmiLbsPerHeadPreviousDay = dmiLbsPerHead * 0.9; // TODO: this should be the real value
  const afLbsPreviousDay = afLbsTotal * 0.9; // TODO: this should be the real value
  const afLbsPerHeadPreviousDay = afLbsPerHead * 0.9; // TODO: this should be the real value
  const dmAsPercentOfBWPreviousDay = 0.01; // 1% // TODO: this should be the real value

  const drops = calls.map<CallFeedRecurringDropUiState>(({ dropId, rationId, amount }, i) => ({
    dropId,
    distributionPercent: distributionPercents[i] ?? 'one-time',
    rationId: {
      params: i,
      value: rationId,
      onChange: modifiers.onChangeDropRation,
    },
    lbsCalled: {
      params: i,
      value: amount,
      onChange: modifiers.onChangeDropAmount,
    },
    lbsFed: 0,
    onDelete: plan.method === PlanCallMethod.ByDrop ? modifiers.onDeleteRecurringDrop : undefined,
  }));

  return {
    rationId,
    dmiLbsPerHeadPreviousDay,
    dmiLbsPerHead: {
      value: dmiLbsPerHead,
      onChange: modifiers.onChangeDmiPerHead,
      disabled: penLevelLocked || plan.callUnit === PlanCallUnit.AsFedLbsPerHead,
    },
    dmiLbsPerHeadDayDifference: dmiLbsPerHead - dmiLbsPerHeadPreviousDay,
    afLbsPerHeadPreviousDay,
    afLbsPerHead: {
      value: afLbsPerHead,
      onChange: modifiers.onChangeAfPerHead,
      disabled: penLevelLocked || plan.callUnit === PlanCallUnit.DmiLbsPerHead,
    },
    afLbsPerHeadDayDifference: afLbsPerHead - afLbsPerHeadPreviousDay,
    afLbsPreviousDay,
    afLbsDayDifference: afLbsTotal - afLbsPreviousDay,
    afLbs: {
      value: afLbsTotal,
      onChange: modifiers.onChangeAfTotal,
      disabled: penLevelLocked || plan.callUnit !== PlanCallUnit.AsFedLbs,
    },
    dmAsPercentOfBWPreviousDay,
    dmAsPercentOfBW,
    dmAsPercentOfBWDayDifference: (dmAsPercentOfBW ?? 0) - (dmAsPercentOfBWPreviousDay ?? 0),
    fedLbs: sumBy(drops, (d) => d.lbsFed),
    drops,
    oneTimeDrops: Empty,
    onAddOneTimeDrop: undefined,
    onAddRecurringDrop: plan.method === PlanCallMethod.ByDrop ? modifiers.onAddRecurringDrop : undefined,
    onResetOverrides:
      wholePlan.method === PlanCallMethod.ByDistribution && wholePlan.distributionTomorrowOverride
        ? modifiers.onResetOverrides
        : undefined,
  };
}

/** Calculates the value to show in the ration dropdown that applies to the whole pen given the current selection of the individual drops */
function wholePenRation(rationIds: readonly number[]): number | 'multiple' | undefined {
  if (rationIds.length === 0) return undefined;
  const unique = new Set(rationIds);
  if (unique.size === 1) return rationIds[0];
  return 'multiple';
}

type CallFeedUiStateArgs = {
  yesterdayNumHead: number;
  currentHead: number;
  rationIdToDmRatio: RationToDryMatterRatio;
  planYesterday: PenCallPlan;
  data: PlanAndObservations;
  setData: (fn: (prev: PlanAndObservations) => PlanAndObservations) => void;
  dropsMapYesterday: readonly CallFeedDropIdAndActual[];
  dropsMap: CallFeedDropsMap;
  feedingMethod: FeedingMethod;
  /** Weight per head of the pen yesterday */
  yesterdayWeightPerHeadLbs: number | null;
  currentWeightPerHeadLbs: number | null;
  defaultRationId: number;
};

/**
 * The output of the useCallFeedUiState hook
 * Represents the state of the UI for Call Feed along with corresponding modifiers for mutating that state
 */
export type CallFeedUiState = {
  /** The fields for today  */
  today: CallFeedDayUiState;
  /** The fields for tomorrow  */
  tomorrow: CallFeedDayUiState;
  /** undefined for per-drop plans */
  distributionPercents: undefined | Input<readonly number[]>;
  observations: {
    bunkScore: Input<BunkScoreValue | undefined>;
  };
};

// TODO: re-evaluate sharing a common type for today and tomorrow. This is probably incorrect as not everything in today is necessarily relevant for tomorrow and vice versa (e.g. addOneTimeDrop)
/** Represents the state of the UI for a particular day in Call Feed */
export type CallFeedDayUiState = {
  /** Pen level ration dropdown */
  rationId: Input<number | undefined | 'multiple', number>;
  /** Dry matter intake lbs per head value of the previous day */
  dmiLbsPerHeadPreviousDay: number;
  /** Dry matter intake lbs per head field for the pen for the day */
  dmiLbsPerHead: Input<number>;
  /** Dry matter intake lbs per head difference value from previous day to this day */
  dmiLbsPerHeadDayDifference: number;
  /** As-fed lbs per head value of the previous day */
  afLbsPerHeadPreviousDay: number;
  /** As-fed lbs per head field for the pen for the day */
  afLbsPerHead: Input<number>;
  /** As-fed lbs per head difference value from previous day to this day */
  afLbsPerHeadDayDifference: number;
  /** As-fed lbs whole-pen value of the previous day */
  afLbsPreviousDay: number;
  /** As-fed lbs whole-pen difference value from previous day to this day */
  afLbsDayDifference: number;
  /** As-fed lbs wole-pen field for the pen for the day */
  afLbs: Input<number>;
  /** Dry Matter as percent of Body Weight */
  dmAsPercentOfBW: number | null;
  /** Dry Matter as percent of Body Weight value of the previous day */
  dmAsPercentOfBWPreviousDay: number | null;
  /** Dry Matter as percent of Body Weight difference value from previous day to this day */
  dmAsPercentOfBWDayDifference: number;
  /** The total fed lbs for the pen for the day */
  fedLbs: number;
  /** Recurring drops for the pen for the day */
  drops: readonly CallFeedRecurringDropUiState[];
  /** One-time drops for the pen for the day */
  oneTimeDrops: readonly CallFeedOneTimeDropUiState[];
  /** Add one-time drop button */
  onAddOneTimeDrop: ((rationId: number) => void) | undefined;
  /** Add recurring drop button */
  onAddRecurringDrop: ((rationId: number) => void) | undefined;
  /** Revert to default distribution, remove any overrides */
  onResetOverrides: (() => void) | undefined;
};

/** Represents the state of the UI for a single recurring drop in Call Feed */
export type CallFeedRecurringDropUiState = {
  /** The id of the drop if it exists */
  dropId: number | undefined;
  /** The distribution percent of the drop normally, or 'one-time' if this drop no longer corresponds to a regular distribution */
  distributionPercent: 'one-time' | number;
  /** Ration dropdown for the drop */
  rationId: Input<number>;
  /** Called lbs field for the drop */
  lbsCalled: Input<CallAmount>;
  /** Fed lbs value for the drop */
  lbsFed: number;
  /** Delete button for the drop (by-drop only) */
  onDelete: ((params: number) => void) | undefined;
};

/** Represents the state of the UI for a single one-time drop in Call Feed */
export type CallFeedOneTimeDropUiState = {
  /** The id of the drop if it exists */
  id: number | undefined;
  /** Ration dropdown for the drop */
  rationId: Input<number>;
  /** Called lbs field for the drop */
  lbsCalled: Input<number>;
  /** Fed lbs value for the drop */
  lbsFed: number;
  /** Delete button for the drop */
  onDelete: ((params: number) => void) | undefined;
};
